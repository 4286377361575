import { Typography, Box, styled } from '@material-ui/core';
import { flywheelColors } from 'constants/colors';
import paypalCardIcon from 'assets/fw-card-paypal.svg';

const StyledBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${flywheelColors.gray15}`,
  backgroundColor: flywheelColors.gray2,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  borderRadius: '4px',
  padding: '30px',
  minHeight: '200px',
  // Breakpoint which triggers on smaller screens
  [theme.breakpoints.down('sm')]: {
    padding: '20px',
  },
}));

const EmailText = styled(Typography)({
  width: '260px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  textAlign: 'left',
});

interface Props {
  email: string;
}

const PaypalCard = ({ email }: Props) => {
  return (
    <StyledBox>
      <img src={paypalCardIcon} alt="Paypal payment type" />
      <EmailText variant="h4" title={email}>
        {email}
      </EmailText>
      <Typography variant="h6">PAYPAL</Typography>
    </StyledBox>
  );
};

export default PaypalCard;
