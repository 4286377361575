import { DialogContent, Typography, Link, styled } from '@material-ui/core';

const StyledTypography = styled(Typography)({
  paddingBottom: '24px',
});

const BadgeModalContent = () => {
  return (
    <DialogContent>
      <StyledTypography>
        At WP Engine we are confident in our products and services, which is why we offer a 60-day money back guarantee.
        If you are not completely satisfied within 60 days of purchasing a new hosting plan, you may submit a
        cancellation request ticket through your customer account portal and request a full refund. If you would like to
        downgrade your eCommerce plan within 60 days of upgrading, email your Account Manager or{' '}
        <Link href="mailto: billing@wpengine.com" target="_blank" rel="noreferrer">
          billing@wpengine.com
        </Link>{' '}
        to request a refund.
      </StyledTypography>
      <StyledTypography>
        Please note: All new Startup, Professional, Growth, and Scale plans are included in the 60-day money back
        guarantee. All eCommerce dedicated custom plans are included in this guarantee. However, all other custom
        dedicated plans are excluded from this guarantee. This guarantee does not apply to non-eCommerce plan upgrades
        or any renewals.
      </StyledTypography>
    </DialogContent>
  );
};

export default BadgeModalContent;
