import { ThemeOptions } from '@material-ui/core';
import { colors } from 'constants/colors';

// This theme is used for both Stripe and Cybersource forms.
// The Flywheel form does not inherit from this at all as styles are so different.
const commonTheme: ThemeOptions = {
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButton: {
      variant: 'contained',
    },
    MuiInput: {
      disableUnderline: true,
    },
    MuiTooltip: {
      arrow: true,
      placement: 'top',
    },
  },
  palette: {
    text: {
      primary: colors.mirage,
      disabled: colors.disabled,
    },
    error: {
      main: colors.red,
    },
  },
  overrides: {
    MuiContainer: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
      },
    },
    MuiToolbar: {
      root: {
        height: '88px',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        borderRadius: '4px',
      },
    },
    MuiCssBaseline: {
      '@global': {
        html: {
          height: '100%',
        },
        body: {
          height: '100%',
          display: 'flex',
          fontFamily: "Open Sans, 'system-ui', '-apple-system', sans-serif",
        },
        '#root': {
          display: 'flex',
          flex: '1',
          flexDirection: 'column',
        },
      },
    },
    MuiInputBase: {
      root: {
        border: `1px solid #ccc`,
        borderRadius: '8px',
        backgroundColor: colors.white,
        '&$error': {
          border: `2px solid ${colors.red}`,
        },
        '&$disabled': {
          borderColor: colors.disabled,
        },
      },
      input: {
        fontSize: '1rem',
        height: '36px',
        padding: '0 12px',
        transition: 'border-color .15s ease-in-out, box-shadow .15s ease-in-out',
        borderRadius: '8px !important',
        '&:focus': {
          boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)',
          borderColor: colors.lightBlue,
          borderRadius: '8px',
        },
        '&:disabled': {
          backgroundColor: colors.polar,
          cursor: 'not-allowed',
        },
      },
    },
    MuiInput: {
      formControl: {
        'label + &': {
          marginTop: '0',
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '0.875rem',
        fontWeight: 'bold',
        color: colors.navy,
        '&$focused': {
          color: colors.navy,
        },
      },
    },
    MuiNativeSelect: {
      root: {
        'label + &': {
          margin: '0',
        },
      },
      select: {
        margin: 0,
        '&:disabled': {
          backgroundColor: colors.polar,
          cursor: 'not-allowed',
        },
        'label + &': {
          margin: '0',
        },
      },
    },
    MuiTooltip: {
      arrow: {
        color: 'white',
      },
      tooltip: {
        backgroundColor: 'white',
        color: colors.mirage,
        filter: 'drop-shadow(0px 2px 4px rgba(89, 118, 127, 0.48)) drop-shadow(0px 0px 1px rgba(0, 40, 56, 0.32))',
        fontSize: '1rem',
        padding: '8px 16px',
        '& a:hover': {
          color: colors.royal,
        },
      },
    },
  },
  typography: {
    // 1.75rem  | 28px
    // 1.5rem   | 24px
    // 1.25rem  | 20px
    // 1rem     | 16px
    // 0.875rem | 14px
    // 0.75rem  | 12px
    h1: {
      fontSize: '1.75rem',
      fontWeight: 'bold',
      marginBottom: '16px',
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      marginBottom: '16px',
    },
    h3: {
      fontSize: '2rem',
      marginBottom: '16px',
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 'bold',
      marginBottom: '16px',
    },
    h5: {
      fontSize: '0.875rem',
      fontWeight: 'bold',
      marginBottom: '16px',
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
    caption: {
      fontSize: '0.875rem',
    },
    subtitle1: {
      fontSize: '0.75rem',
    },
  },
};

export default commonTheme;
