import { styled, FormHelperText } from '@material-ui/core';

const StyledErrorText = styled(FormHelperText)({
  gridColumn: '-2',
});

interface Props {
  children: React.ReactNode;
  error?: boolean;
}

const FormErrorText = ({ children, error }: Props) => {
  return <StyledErrorText error={error}>{children}</StyledErrorText>;
};

export default FormErrorText;
