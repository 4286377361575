import { styled, InputBase } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import FormErrorText from './FormErrorText';
import FormFieldLabel from './FormFieldLabel';
import FormFieldContainer from './FormFieldContainer';
import InfoTip from 'components/InfoTip';
import { FormStatus } from 'models/Billing';

const StyledInput = styled(InputBase)(({ theme }) => ({
  alignSelf: 'center',
  [theme.breakpoints.down('sm')]: {
    margin: 0,
  },
}));

interface Props {
  label: string;
  name: string;
  maxChars?: number;
  tooltip?: string;
}

const FormTextField = ({ label, name, maxChars, tooltip }: Props) => {
  const { status } = useFormikContext();
  const isSubmitting = status === FormStatus.Submitting;
  const [field, meta] = useField(name);

  return (
    <FormFieldContainer error={meta.touched && Boolean(meta.error)} disabled={isSubmitting}>
      <FormFieldLabel htmlFor={name}>
        {label}
        {tooltip && <InfoTip placement="top" title={tooltip} arrow={true} size={12} />}
      </FormFieldLabel>
      <StyledInput
        id={name}
        type="text"
        inputProps={{
          maxLength: maxChars,
        }}
        {...field}
      />
      <FormErrorText>{meta.touched && meta.error}</FormErrorText>
    </FormFieldContainer>
  );
};

export default FormTextField;
