import axios, { AxiosRequestConfig } from 'axios';

const FetchAPI = {
  get: async (url: string) => {
    const response = await axios.get(url);
    return response?.data;
  },
  patch: async (url: string, payload?: unknown) => {
    try {
      const config: AxiosRequestConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const response = await axios.patch(url, payload, config);
      return response?.data;
    } catch (error: any) {
      return Promise.reject(error?.response?.data);
    }
  },
  post: async (url: string, payload?: unknown, timeout: number = 60000) => {
    try {
      const config: AxiosRequestConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
        timeout: timeout,
      };
      const response = await axios.post(url, payload, config);
      return response?.data;
    } catch (error: any) {
      return Promise.reject(error?.response?.data);
    }
  },
};

export default FetchAPI;
