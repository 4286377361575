import { styled, FormLabel, FormLabelProps } from '@material-ui/core';

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  alignSelf: 'center',
  minWidth: '130px',
  display: 'flex',
  gap: '10px',
}));

interface Props {
  children: React.ReactNode;
}

const FormFieldLabel = ({ children, ...otherProps }: Props & FormLabelProps) => {
  return <StyledFormLabel {...otherProps}>{children}</StyledFormLabel>;
};

export default FormFieldLabel;
