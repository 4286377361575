// Official colors from the Unicorn Style Guide: https://bit.ly/3dcHDTY
// Only colors from the style guide should be added here.
export const colors = {
  cherry: '#D21B46',
  cherryShade: '#991433',
  cherryLight: '#FBE8EC',
  cherryTint: '#FE6B75',
  curry: '#FFCB52',
  dollarbillzShade: '#35872F',
  disabled: '#6A92B8',
  helitrope: '#7A1BA6',
  helix: '#180038',
  laptisShade: '#0076DC',
  lightBlue: '#66afe9',
  mirage: '#002838',
  mirageShade: '#001720',
  mirageTint: '#173B4A',
  polar10: '#CFDDE9',
  polar25: '#9DB7D1',
  polar: '#F4F7FA',
  royal: '#7E5CEF',
  royalShade: '#5C43AE',
  royalTint: '#9579F2',
  seafoam: '#50E3C2',
  sunset: '#FF6119',
  sunsetShade: '#DB4400',
  tiffany: '#0ECAD4',
  titanium: '#59767F',
  white: '#FFFFFF',
  blue: '#006BD6',
  blueShade: '#0059b2',
  navy: '#002447',
  grey: '#f8f9fa',
  medGrey: '#5B6C74',
  red: '#DD1243',
};

export const flywheelColors = {
  // Black
  black: '#000',

  // White
  white: '#fff',

  // Gray
  gray: '#5d5e5e',
  graydark: '#262727',
  graydark50: '#434344',
  gray75: '#9f9c9c',
  gray25: '#c7c4c4',
  gray15: '#e7e7e7',
  gray5: '#f7f6f6',
  gray2: '#fafafa',
  gray1: '#fcfcfc',

  // Blue
  blue: '#50c6db',
  bluelink: '#00c1db',
  bluedark: '#01516e',
  bluedark25: '#296a82',
  bluedark50: '#338199',
  blue75: '#b0e0ea',
  blue25: '#d6eef2',
  blue15: '#def6fa',
  blue5: '#8fd6e3',

  // Green
  green: '#51bb7b',
  greendark: '#267048',
  greendark50: '#419564',
  green75: '#add9b8',
  green25: '#d4ead9',
  green15: '#d8f0de',
  green2: '#f4faf7',

  // Yellow
  yellow: '#f0ce15',
  yellowdark: '#9a7700',
  yellowdark50: '#c3a028',
  yellow75: '#f6e299',
  yellow25: '#f9edc9',

  // Orange
  orange: '#f47820',
  orangedark: '#8e4402',
  orangedark50: '#bb5f23',
  orange75: '#fab889',
  orange25: '#fdd9be',
  orange15: '#ffe4cf',

  // Red
  red: '#ef4e65',
  reddark: '#8c2738',
  reddark50: '#ba3e51',
  red75: '#f18085',
  red25: '#fad1cd',
  red15: '#ffe2df',
  debugred: 'rgba(red, 0.5)',

  // Pink
  pink: '#e0368c',
  pinkdark: '#851252',
  pinkdark50: '#af2a6f',
  pink75: '#eba0c0',
  pink25: '#f3cddd',

  // Purple
  purple: '#8351a0',
  purpledark: '#4e2760',
  purpledark50: '#693f7e',
  purple75: '#b69cc8',
  purple25: '#d5c9e2',
  purple15: '#e5d8f3',
};
