import React from 'react';
import { Button as MuiButton } from '@material-ui/core';
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';

// There is a confusing misalignment in the mapping of terminology between
// Material UI buttons and Unicorn buttons.
// Therefore, here's a table to make the mapping easier to understand:

//  MUI         |   UNICORN
//  -------------------------
//  Default     |   Secondary
//  Primary     |   Primary
//  Secondary   |   Destruct
//  Outlined    |   Tertiary
//  Disabled    |   Disabled
//  Text        |   Text Link

type UnicornButtonKind = 'primary' | 'secondary' | 'tertiary' | 'destruct' | 'text';
type MuiButtonVariant = 'contained' | 'outlined' | 'text';
type MuiButtonColor = 'default' | 'primary' | 'secondary';

interface ButtonProps {
  kind?: UnicornButtonKind;
}

const getColor = (kind: UnicornButtonKind): MuiButtonColor => {
  if (kind === 'primary') {
    return 'primary';
  }

  if (kind === 'destruct') {
    return 'secondary';
  }

  return 'default';
};

const getVariant = (kind: UnicornButtonKind): MuiButtonVariant => {
  if (kind === 'tertiary') {
    return 'outlined';
  }

  if (kind === 'text') {
    return 'text';
  }

  return 'contained';
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps & MuiButtonProps>((props, ref) => {
  const { kind = 'primary', children, ...other } = props;

  return (
    <MuiButton color={getColor(kind)} ref={ref} variant={getVariant(kind)} {...other}>
      {children}
    </MuiButton>
  );
});

export default Button;
