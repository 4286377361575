import { useState } from 'react';
import { useBilling } from 'hooks/useBilling';
import ProgressStepper from 'components/ProgressStepper';
import { Formik, FormikHelpers } from 'formik';
import { BillingPayload, FormikBillingValues, FormStatus } from 'models/Billing';
import { initialValues, PageContainer, validationSchema } from './SignupPaymentPage';
import BillingContainer from 'components/BillingContainer';

const StripePaymentPage = ({ portalConfirmationPageUrl }: { portalConfirmationPageUrl: string }) => {
  const { sendBillingInfo, getStripeToken } = useBilling();
  const [errorMessage, setErrorMessage] = useState<string>('');

  return (
    <PageContainer>
      <ProgressStepper />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values: FormikBillingValues, helpers: FormikHelpers<FormikBillingValues>) => {
          // Use formik state to set global form status
          helpers.setStatus(FormStatus.Submitting);
          setErrorMessage('');

          // Fetch stripe token
          const stripeToken = await getStripeToken(values);

          if (stripeToken?.error) {
            helpers.setStatus(FormStatus.Error);
            setErrorMessage('Error Processing your payment. Please correct the information.');
            return;
          }

          if (stripeToken && stripeToken?.token) {
            const payload: BillingPayload = {
              address1: values.billingAddress,
              address2: values.billingAddress2,
              city: values.city,
              state: values.region,
              country: values.country,
              zip: values.zip,
              cc_token: stripeToken.token.id,
              vat_id: values.vatId,
              cardholder_name: values.cardholderName,
            };

            sendBillingInfo(payload, {
              onSuccess: () => {
                // Go to confirmation page
                window.location.href = portalConfirmationPageUrl;
              },
              onError: (error: any) => {
                // Update formik state
                helpers.setStatus(FormStatus.Error);

                // Display error alert box
                if (error?.user_actionable === true) {
                  setErrorMessage(error.message);
                } else {
                  setErrorMessage('We couldn’t complete your purchase. Please contact our sales team.');
                }
              },
            });
          }
        }}
      >
        <BillingContainer errorMessage={errorMessage} />
      </Formik>
    </PageContainer>
  );
};

export default StripePaymentPage;
