import {
  AppBar,
  Toolbar,
  CssBaseline,
  ThemeProvider,
  styled,
  Box,
  createTheme,
  Container,
  Typography,
} from '@material-ui/core';
import logoBlack from 'assets/wpe-full-logo-black-2024.svg';
import commonTheme from 'themes/commonTheme';
import updatePaymentInfoTheme from 'themes/updatePaymentTheme';

interface ErrorPageProps {
  message?: string;
}

const ErrorPage = ({ message }: ErrorPageProps) => {
  const AppContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingBottom: '30px',
  });

  const WPELink = styled('a')({
    height: '55px',
    width: '216px',
  });

  const WPELogo = styled('img')({
    height: '55px',
    width: '216px',
  });

  const StyledErrorMessage = styled(Typography)(() => ({
    marginBottom: '40px',
    marginTop: '40px',
  }));

  const defaultTheme = createTheme(updatePaymentInfoTheme, commonTheme);

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <AppContainer>
        <AppBar position="static" color="secondary" elevation={0}>
          <Toolbar>
            <WPELink href="https://wpengine.com">
              <WPELogo src={logoBlack} alt="WP Engine Logo" />
            </WPELink>
          </Toolbar>
        </AppBar>

        <Container maxWidth="md">
          <Typography variant="h3">Application Error</Typography>
          <StyledErrorMessage>{message}</StyledErrorMessage>
        </Container>
      </AppContainer>
    </ThemeProvider>
  );
};

export default ErrorPage;
