import { CartItem } from 'models/CartSummary';

export const mockCartAddons: CartItem[] = [
  {
    description: 'Smart Plugin Manager',
    price: '$10.00',
  },
  {
    description: 'Additional Sites (3)',
    price: '$45.00',
  },
];
