import { useQuery } from 'react-query';
import FetchAPI from 'services/FetchAPI';
import { TRANSACTION_URL } from 'constants/urls';
import { Transaction } from 'models/Transaction';
import { useURLParams } from 'hooks/useURLParams';

// This hook is used retrieve transaction information to pass on to the confirmation page.
export const useTransaction = () => {
  const transactionId = useURLParams('transaction_id');

  const { status, data } = useQuery('transactionData', () => {
    return FetchAPI.get(`${TRANSACTION_URL}/${transactionId}/`);
  });

  const transaction: Transaction = data || null;

  return { status, transaction };
};
