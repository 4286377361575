import { Box, Container, styled, Typography } from '@material-ui/core';
import { flywheelColors } from 'constants/colors';
import DualLogoHeader from 'components/DualLogoHeader';
import FlywheelForm from 'pages/flywheel-add-payment/FlywheelForm';

export const PageContainer = styled(Container)(({ theme }) => ({
  display: 'grid',
  gap: '10px',
  maxWidth: '673px',
  gridTemplateColumns: '1fr',
  gridTemplateRows: '150px auto 1fr',
  gridTemplateAreas: `
    'dual-logo-header'
    'form'
  `,
  // Breakpoint which triggers on smaller screens
  [theme.breakpoints.down('sm')]: {
    gridTemplateRows: '200px auto',
    minWidth: 'unset',
  },
}));

const HeadingContainer = styled(Box)({
  textAlign: 'center',
  paddingBottom: '44px',
});

const StyledBox = styled(Box)({
  textAlign: 'center',
  backgroundColor: flywheelColors.white,
});

const FlywheelAddPaymentMethodPage = () => {
  return (
    <PageContainer>
      <DualLogoHeader />
      <HeadingContainer>
        <Typography variant="h1">Add payment method</Typography>
        <Typography variant="h3" component="h2">
          This payment method will be added to your Flywheel account.
        </Typography>
      </HeadingContainer>
      <StyledBox>
        <FlywheelForm />
      </StyledBox>
    </PageContainer>
  );
};

export default FlywheelAddPaymentMethodPage;
