import { Typography, Box, styled } from '@material-ui/core';
import { flywheelColors } from 'constants/colors';
import ErrorIcon from 'assets/ErrorIcon';
import { useTransactionDetails } from 'hooks/useTransactionDetails';

const StyledAlertBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '0.875rem',
  marginBottom: 0,
  marginTop: '21px',
  padding: '24px',
  borderRadius: '16px',

  [theme.breakpoints.down('sm')]: {
    marginBottom: '21px',
    marginTop: '15px',
  },
}));

const StyledErrorIcon = styled(ErrorIcon)({
  display: 'flex',
  marginRight: '16px',
});

interface Props {
  children: React.ReactNode;
}

const AlertBox = ({ children }: Props) => {
  const { transactionDetails } = useTransactionDetails();

  if (!transactionDetails) {
    return null;
  }

  return (
    <>
      {transactionDetails.billing_system === 'flywheel' ? (
        // Flywheel Error Box
        <StyledAlertBox color={flywheelColors.white} bgcolor="error.main" borderRadius="borderRadius">
          <Typography>{children}</Typography>
        </StyledAlertBox>
      ) : (
        // WP Engine Error Box
        <StyledAlertBox color="text.primary" bgcolor={'#FCE7EC'}>
          <StyledErrorIcon />
          <Typography>{children}</Typography>
        </StyledAlertBox>
      )}
    </>
  );
};
export default AlertBox;
