import { FormHelperText, makeStyles, styled } from '@material-ui/core';
import FormFieldContainer from '../BillingForm/FormFieldContainer';
import { colors } from 'constants/colors';
import LockIcon from '@material-ui/icons/Lock';
import FormFieldLabel from 'components/BillingForm/FormFieldLabel';

export const CreditCardForm = styled(FormFieldContainer)({
  '& .MuiFormHelperText-root, & .credit_card_label-error': {
    color: colors.cherry,
  },
  '& .secure_connection-text  .MuiFormHelperText-root': {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  '& .secure_connection-text': {
    gridColumn: '-2',
  },
  '& #cvc_input, & #credit-card_input': {
    height: '100%',
  },
  '& #cvc_input iframe, & #credit-card_input iframe': {
    height: '100%',
  },
  '& #cvc_input': {
    maxWidth: '4em',
  },
  '& #expiryDate': {
    marginLeft: 'auto',
    minWidth: '4em',
  },
});

export const CreditCardFieldLabel = styled(FormFieldLabel)({});

export const useStyles = makeStyles({
  cardIcon: {
    fontSize: '30px',
    verticalAlign: 'middle',
    color: colors.disabled,
  },
  cardIconWrapper: {
    marginTop: '1px',
    whiteSpace: 'nowrap',
  },
});

export const StyledHelperText = styled(FormHelperText)({
  verticalAlign: 'top',
  fontSize: '14px',
  display: 'flex',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  gap: '7px',
  marginTop: '6px',
});

export const StyledLockIcon = styled(LockIcon)({
  fontSize: '20px',
});

export const PaymentInputsWrapperStyles = (isSubmitting: boolean) => {
  if (isSubmitting) {
    return {
      inputWrapper: {
        base: {
          display: 'flex',
          borderRadius: '8px',
          justifyContent: 'space-between',
          borderColor: `${colors.disabled}`,
          backgroundColor: `${colors.polar}`,
        },
      },
      errorText: {
        base: 'display: none',
      },
    };
  }

  return {
    inputWrapper: {
      base: {
        display: 'flex',
        justifyContent: 'flex-start',
        boxShadow: 'none',
        borderColor: 'rgb(204, 204, 204)',
        borderRadius: '8px',
      },
      errored: `border: 1px solid ${colors.cherry}`,
      focused: {
        outline: '0',
        boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)',
        borderColor: `${colors.lightBlue}`,
      },
    },
    errorText: {
      base: 'display: none',
    },
  };
};
