import { useFormikContext } from 'formik';
import Button from './Button';
import LockIcon from '@material-ui/icons/Lock';
import { CircularProgress } from '@material-ui/core';
import { FormStatus } from 'models/Billing';

const UpdateButton = () => {
  const { status } = useFormikContext();
  const isSubmitting = status === FormStatus.Submitting;
  const isLoading = status === FormStatus.Loading;
  const icon = isSubmitting ? null : <LockIcon />;
  const loading_icon = isSubmitting ? <CircularProgress size={18} color="inherit" /> : null;

  const buttonText = isSubmitting ? 'Updating' : 'Update';

  return (
    <Button
      form="billingForm"
      kind="primary"
      type="submit"
      disabled={isSubmitting || isLoading}
      startIcon={icon}
      endIcon={loading_icon}
    >
      {buttonText}
    </Button>
  );
};

export default UpdateButton;
