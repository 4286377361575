import { useQuery } from 'react-query';
import FetchAPI from 'services/FetchAPI';
import { TRANSACTION_DETAILS_URL } from 'constants/urls';
import { TransactionDetails } from 'models/TransactionDetails';
import { useURLParams } from 'hooks/useURLParams';

// This hook is used retrieve transaction information which the app needs when it first loads.
// e.g which payment page to display, what redirect url is so we know where to send the user once they submit their info etc.
export const useTransactionDetails = () => {
  const transactionId = useURLParams('transaction_id');
  const salesOrder = useURLParams('sales_order');

  const { status, data } = useQuery(
    'transactionDetailsData',
    () => {
      return FetchAPI.get(`${TRANSACTION_DETAILS_URL}/${transactionId}/`);
    },
    { enabled: !salesOrder, retry: false },
  );

  const transactionDetails: TransactionDetails = data || null;

  return { status, transactionDetails };
};
