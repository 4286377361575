import FormSelectField from './FormSelectField';
import { DropdownOption } from 'models/DropdownOption';
import { Country } from 'models/Country';
import { useFormikContext } from 'formik';

interface Props {
  countries: Country[];
}

const CountrySelector = ({ countries }: Props) => {
  const { setFieldValue } = useFormikContext();

  const dropdownOptions: DropdownOption[] = countries?.map((country: Country) => {
    const option: DropdownOption = { label: country.name, value: country.code };
    return option;
  });

  const handleCountryChanged = () => {
    // Reset the "Region" field value every time the country changes.
    setFieldValue('region', '');
  };

  return <FormSelectField label="Country" name="country" options={dropdownOptions} onChange={handleCountryChanged} />;
};

export default CountrySelector;
