import { ReactElement, useEffect, useState } from 'react';

interface Props {
  children: ReactElement;
}

const CybersourceContainer = ({ children }: Props) => {
  const [loaded, setLoaded] = useState(false);

  function loadScript(src: string) {
    return new Promise(function (resolve) {
      let script = document.createElement('script');
      script.src = src;

      script.onload = () => resolve(script);

      document.body.append(script);
    });
  }

  useEffect(() => {
    loadScript('/js/flex-microform-v2.min.js').then(() => {
      setLoaded(true);
    });
  }, []);

  if (loaded) {
    return children;
  }

  return null;
};

export default CybersourceContainer;
