// Parses a specific param from the URL
export const useURLParams = (queryParam: string): string | null => {
  const params = new URLSearchParams(window.location.search);
  const result = params.get(queryParam);

  if (!result) {
    return null;
  }

  // Remove all slashes from string
  return result.replace(/\//g, '');
};
