import { Box, styled, Typography } from '@material-ui/core';
import ErrorAlert from 'components/ErrorAlert';
import BillingForm from 'components/BillingForm/BillingForm';
import Sidebar from 'components/Sidebar';
import { colors } from 'constants/colors';
import CancelButton from 'components/CancelButton';
import UpdateButton from 'components/UpdateButton';

const UpdateStyledBox = styled(Box)({
  display: 'flex',
  gap: '16px',
  paddingTop: '10px',
  justifyContent: 'flex-end',
});

const StyledBox = styled('main')({
  display: 'flex',
  flexDirection: 'column',
  flex: '2',
  gridArea: 'form',
  backgroundColor: colors.white,
  border: `solid 1px ${colors.polar10}`,
  padding: '30px 25px',
  borderRadius: '16px',
});

interface Props {
  errorMessage: string;
  cybersource?: boolean;
  portalInvoicePageUrl?: string;
  isUpdatePage?: boolean;
}

const BillingContainer = ({ errorMessage, cybersource, portalInvoicePageUrl, isUpdatePage }: Props) => {
  if (isUpdatePage) {
    return (
      <>
        <Typography variant="h3">Update payment information</Typography>
        <ErrorAlert message={errorMessage} />
        <BillingForm cybersource={cybersource} />
        <UpdateStyledBox>
          <CancelButton portalInvoicePageUrl={portalInvoicePageUrl} />
          <UpdateButton />
        </UpdateStyledBox>
      </>
    );
  }

  return (
    <>
      <StyledBox>
        <ErrorAlert message={errorMessage} />
        <BillingForm cybersource={cybersource} />
      </StyledBox>
      <Sidebar />
    </>
  );
};

export default BillingContainer;
