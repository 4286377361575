import { vatCountries } from 'constants/vatCountries';
import { bannedCountries } from 'constants/bannedCountries';
import { Country } from 'models/Country';

const allCountries = require('country-region-data/data.json');

interface CountryData {
  countryName: string;
  countryShortCode: string;
  regions: { name: string; shortCode: string }[];
}

// This hook is used retrieve a list of countries in which WPE can accept signups from.
export const useCountries = () => {
  const countries: Country[] = (allCountries as CountryData[])
    .filter(({ countryShortCode }) => !bannedCountries.includes(countryShortCode))
    .sort((a, b) => a.countryName.localeCompare(b.countryName))
    .map(({ countryName, countryShortCode }) => ({
      name: countryName,
      code: countryShortCode,
    }));

  return { countries, vatCountries };
};
