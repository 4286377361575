import { styled, Typography, Box, Tabs, Tab, useMediaQuery, useTheme } from '@material-ui/core';
import { flywheelColors } from 'constants/colors';
import paypalLogo from 'assets/fw-paypal.svg';
import creditCardIcon from 'assets/fw-credit-card.svg';
import checkmarkIcon from 'assets/fw-checkmark.svg';

interface SelectedTabTickContainerProps {
  isActiveTab: boolean;
}

const SelectedTabTickContainer = styled(({ isActiveTab, ...other }) => <div {...other} />)({
  display: 'flex',
  position: 'absolute',
  top: 0,
  right: 0,
  alignItems: 'center',
  justifyContent: 'center',
  transformOrigin: '100% 0',
  color: flywheelColors.white,
  borderWidth: '0px 40px 40px 0',
  borderStyle: 'solid',
  borderColor: `transparent ${flywheelColors.blue} transparent transparent`,
  // Show the arrow tick with animation when the tab is selected
  transform: (props: SelectedTabTickContainerProps) => (props.isActiveTab ? 'scale(1)' : 'scale(0)'),
  opacity: (props: SelectedTabTickContainerProps) => (props.isActiveTab ? '1' : '0'),
  width: (props: SelectedTabTickContainerProps) => (props.isActiveTab ? '40px' : '0'),
  height: (props: SelectedTabTickContainerProps) => (props.isActiveTab ? '40px' : '0'),
  transition: (props: SelectedTabTickContainerProps) => (props.isActiveTab ? 'all 0.2s ease 0s' : 'all 0s ease 0s'),
});

const StyledTickIcon = styled('img')({
  left: '29px',
  top: '12px',
  position: 'relative',
  height: '10px',
});

const CreditCardPaymentTypeImage = styled('img')({
  height: '25px',
  marginTop: '3px',
});

const PaypalPaymentTypeImage = styled('img')(({ theme }) => ({
  height: '22px',
  marginTop: '3px',
  // Breakpoint which triggers on smaller screens
  [theme.breakpoints.down('sm')]: {
    height: '20px',
  },
}));

export enum PaymentType {
  CREDIT_CARD = 0,
  PAYPAL = 1,
}

interface PaymentTypeTabsProps {
  onTabChanged: (tabIndex: number) => void;
  selectedTabIndex: number;
  disabled: boolean;
}

interface TabPanelProps {
  children?: React.ReactNode;
  tabIndex: number;
  currentTabIndex: number;
}

const a11yProps = (index: number) => {
  return {
    id: `payment-type-tab-${index}`,
    'aria-controls': `payment-type-tabpanel-${index}`,
  };
};

export const PaymentTypeTabPanel = ({ children, currentTabIndex, tabIndex, ...other }: TabPanelProps) => {
  return (
    <div
      role="tabpanel"
      hidden={currentTabIndex !== tabIndex}
      id={`payment-type-tabpanel-${tabIndex}`}
      aria-labelledby={`payment-type-tab-${tabIndex}`}
      {...other}
    >
      <Box paddingY={'30px'} display="flex" justifyContent="center">
        {children}
      </Box>
    </div>
  );
};

const PaymentTypeTabs = ({ onTabChanged, selectedTabIndex, disabled }: PaymentTypeTabsProps) => {
  const theme = useTheme();
  const smallViewport = useMediaQuery(theme.breakpoints.down('sm'));

  const onPaymentTypeTabChanged = (event: React.ChangeEvent<{}>, tabIndex: number) => {
    onTabChanged(tabIndex);
  };

  return (
    <Box mt={3}>
      <Tabs
        value={selectedTabIndex}
        onChange={onPaymentTypeTabChanged}
        aria-label="Payment type tabs"
        selectionFollowsFocus
        orientation={smallViewport ? 'vertical' : 'horizontal'}
        variant="fullWidth"
      >
        <Tab
          icon={<CreditCardPaymentTypeImage alt="Credit card" src={creditCardIcon} />}
          label={
            <Box display="flex">
              <Typography variant="body1">Add new credit card</Typography>
              <SelectedTabTickContainer isActiveTab={selectedTabIndex === PaymentType.CREDIT_CARD}>
                <StyledTickIcon src={checkmarkIcon} alt="Selected tab." aria-hidden="true" />
              </SelectedTabTickContainer>
            </Box>
          }
          disabled={disabled}
          {...a11yProps(PaymentType.CREDIT_CARD)}
        />
        <Tab
          icon={<PaypalPaymentTypeImage alt="Paypal card" src={paypalLogo} />}
          label={
            <Box display="flex">
              <Typography variant="body1">Add PayPal account</Typography>
              <SelectedTabTickContainer isActiveTab={selectedTabIndex === PaymentType.PAYPAL}>
                <StyledTickIcon src={checkmarkIcon} alt="Selected tab." aria-hidden="true" />
              </SelectedTabTickContainer>
            </Box>
          }
          disabled={disabled}
          {...a11yProps(PaymentType.PAYPAL)}
        />
      </Tabs>
    </Box>
  );
};

export default PaymentTypeTabs;
