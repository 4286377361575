import { useMutation, useQuery } from 'react-query';
import FetchAPI from 'services/FetchAPI';
import { STRIPE_CLIENT_SECRET_URL } from 'constants/urls';

import { useURLParams } from './useURLParams';
import { StripeClientSecretRequest } from 'models/Billing';

export const useStripe = () => {
  const transactionId = useURLParams('transaction_id');
  const salesOrder = useURLParams('sales_order');

  // Fetches cybersourceKey
  const { mutate: getClientSecret } = useMutation((payload: StripeClientSecretRequest) => {
    const response = FetchAPI.post(`${STRIPE_CLIENT_SECRET_URL}?transaction_id=${transactionId}`, payload, 120000);
    return response;
  });

  return {
    getClientSecret,
  };
};
