import { useBilling } from 'hooks/useBilling';
import { useState } from 'react';
import { BillingPayload, FormikBillingValues, FormStatus } from 'models/Billing';
import { Container } from '@material-ui/core';
import { Formik, FormikHelpers } from 'formik';
import BillingContainer from 'components/BillingContainer';
import * as Yup from 'yup';
import { ZipRegex } from 'utils/validationUtils';

const validationSchema = Yup.object({
  cardholderName: Yup.string()
    .required(`Name on card is required`)
    .min(2, `Name on card must be greater than 2 characters`),
  billingAddress: Yup.string().required(`Billing address is required`),
  region: Yup.string().required(`State / Region is required`),
  city: Yup.string().required(`City is required`),
  zip: Yup.string().when('country', {
    is: 'US',
    then: Yup.string()
      .matches(ZipRegex, `Zip / Postal code must be 5 digits`)
      .required(`Zip / Postal code is required`),
    otherwise: Yup.string().required(`Zip / Postal code is required`),
  }),
});

const StripeUpdatePaymentPage = ({
  initialValues,
  portalInvoicePageUrl,
}: {
  initialValues: FormikBillingValues;
  portalInvoicePageUrl: string;
}) => {
  const { sendBillingInfo, getStripeToken } = useBilling();
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <Container maxWidth="md">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values: FormikBillingValues, helpers: FormikHelpers<FormikBillingValues>) => {
          // Use formik state to set global form status
          helpers.setStatus(FormStatus.Submitting);
          setErrorMessage('');

          // Fetch stripe token
          const stripeToken = await getStripeToken(values);

          if (stripeToken?.error) {
            helpers.setStatus(FormStatus.Error);
            setErrorMessage('Error Processing your payment. Please correct the information.');
            return;
          }

          if (stripeToken && stripeToken?.token) {
            const payload: BillingPayload = {
              address1: values.billingAddress,
              address2: values.billingAddress2,
              city: values.city,
              state: values.region,
              country: values.country,
              zip: values.zip,
              cc_token: stripeToken?.token?.id,
              cardholder_name: values.cardholderName,
            };

            sendBillingInfo(payload, {
              onSuccess: () => {
                // Go back to the invoice page in portal.
                window.location.href = portalInvoicePageUrl;
              },
              onError: (error: any) => {
                // Update formik state
                helpers.setStatus(FormStatus.Error);

                // Display error alert box
                if (error?.user_actionable === true) {
                  setErrorMessage(error.message);
                } else {
                  setErrorMessage(
                    "We couldn't update your information because of a system failure. Please try again later.",
                  );
                }
              },
            });
          }
        }}
      >
        <BillingContainer errorMessage={errorMessage} portalInvoicePageUrl={portalInvoicePageUrl} isUpdatePage />
      </Formik>
    </Container>
  );
};

export default StripeUpdatePaymentPage;
