import { Box, styled } from '@material-ui/core';
import Badge from 'components/Badging/Badge';
import sslBadgeImage from 'assets/ssl-badge.svg';
import stevieBadgeImage from 'assets/stevie-badge.svg';
import moneyBackBadgeImage from 'assets/mbg-badge.svg';
import { useTransactionDetails } from 'hooks/useTransactionDetails';

const SslLogo = styled('img')({
  height: '50px',
  width: '90px',
});

const MoneyBackLogo = styled('img')({
  height: '72px',
  width: '72px',
});

const StevieLogo = styled('img')({
  height: '78px',
  width: '78px',
});

const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  paddingTop: '30px',
});

const BadgeList = () => {
  const { transactionDetails } = useTransactionDetails();

  if (!transactionDetails) {
    return null;
  }

  return (
    <StyledBox role="list">
      <Badge
        badge={<SslLogo src={sslBadgeImage} alt="Secured by Rapid SSL with a $10,000 warranty" />}
        text="Secure Checkout"
      />
      {transactionDetails.shared_plan && (
        <Badge
          badge={<MoneyBackLogo src={moneyBackBadgeImage} alt="60 Day Money Back Guarantee" />}
          modal={transactionDetails.shared_plan}
        />
      )}
      <Badge
        badge={
          <StevieLogo src={stevieBadgeImage} alt="Award Winning Customer Service, 2021 Silver Stevie Award Winners" />
        }
        text="Award Winning Customer Service"
      />
    </StyledBox>
  );
};

export default BadgeList;
