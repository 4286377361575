import { Typography, Box, styled } from '@material-ui/core';
import { CartItem, CartSummary } from 'models/CartSummary';
import { colors } from 'constants/colors';
import InfoTip from 'components/InfoTip';

const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const ItemRow = styled(Box)({
  display: 'flex',
  flex: 1,
  alignItems: 'baseline',
  gap: '5px',
});

const DiscountRow = styled(ItemRow)({
  color: colors.dollarbillzShade,
});

const DottedLine = styled('div')({
  flexGrow: 1,
  order: 2,
  borderBottom: 'dotted',
  borderBottomColor: 'black',
});

const ItemPrice = styled(Typography)({
  order: 3,
});

const CouponDescription = styled(Typography)({
  marginBottom: 0,
});

interface Props {
  summary: CartSummary;
  tax: string | null;
}

const CartList = ({ summary, tax }: Props) => {
  if (!summary) {
    return null;
  }

  return (
    <StyledBox>
      <ItemRow>
        <Typography variant="h5">{summary.plan.description}</Typography>
        <DottedLine />
        <ItemPrice variant="body1">{summary.plan.price}</ItemPrice>
      </ItemRow>

      {summary.annual_discount && (
        <DiscountRow>
          <Typography variant="h5">Annual discount</Typography>
          <DottedLine />
          <ItemPrice variant="body2">{summary.annual_discount}</ItemPrice>
        </DiscountRow>
      )}

      {summary.addons?.map((item: CartItem) => (
        <ItemRow key={item.description}>
          <Typography variant="h5">{item?.description}</Typography>
          <DottedLine />
          <ItemPrice variant="body2">{item?.price}</ItemPrice>
        </ItemRow>
      ))}

      {summary.coupon && Object.keys(summary.coupon).length > 0 && (
        <Box>
          <DiscountRow>
            <CouponDescription variant="h5">{summary.coupon.description}</CouponDescription>
            <InfoTip placement="top" title="Coupons apply to plan base price only." arrow={true} size={13} />
            <DottedLine />
            <ItemPrice variant="body2">({summary.coupon.value})</ItemPrice>
          </DiscountRow>
          <DiscountRow>
            <Typography variant="subtitle1">{summary.coupon.duration}</Typography>
          </DiscountRow>
        </Box>
      )}
      {tax != null ? (
        <ItemRow key={'Tax'}>
          <Typography variant="h5">{'Tax'}</Typography>
          <DottedLine />
          <ItemPrice variant="body2">{tax}</ItemPrice>
        </ItemRow>
      ) : (
        false
      )}
    </StyledBox>
  );
};

export default CartList;
