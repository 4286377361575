import { CardElement } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent, StripeCardElementOptions } from '@stripe/stripe-js';
import { makeStyles, styled, Box } from '@material-ui/core';
import FormFieldContainer from './FormFieldContainer';
import FormFieldLabel from './FormFieldLabel';
import FormErrorText from './FormErrorText';
import { colors } from 'constants/colors';
import { useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { FormStatus } from 'models/Billing';
import SecureConnectionText from 'components/BillingForm/SecureConnectionText';

const useStyles = makeStyles({
  base: {
    borderRadius: '2px',
    padding: '10px',
  },
  focused: {
    boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)',
    borderColor: colors.lightBlue,
  },
  cardIcon: {
    fontSize: '25px',
    verticalAlign: 'middle',
    color: colors.disabled,
  },
  cardIconWrapper: {
    marginTop: '1px',
    whiteSpace: 'nowrap',
  },
});

const StyledCardElement = styled(CardElement)({
  border: `1px solid #ccc`,
  borderRadius: '8px',
  height: '38px',
});

const SecureConnectionTextContainer = styled(Box)({
  gridColumn: '-2',
  fontSize: '14px',
});

interface Props {
  label: string;
  name: string;
}

const FormCardField = ({ label, name }: Props) => {
  const { status } = useFormikContext();
  const isSubmitting = status === FormStatus.Submitting;
  const styles = useStyles();
  const [, meta, helpers] = useField(name);
  const { setValue } = helpers;
  const [error, setError] = useState('');

  const handleChange = (event: StripeCardElementChangeEvent) => {
    // Card number is valid
    if (event.complete) {
      setValue('complete');
      setError('');
    }
    // Card number is blank
    if (event.empty) {
      setValue('');
      setError('Credit card number is required');
    }
    // Card number is invalid
    if (event.error) {
      setValue(event.error.message);
      setError(event.error.message);
    }
  };

  const CARD_OPTIONS: StripeCardElementOptions = {
    hidePostalCode: true,
    disabled: isSubmitting,
    classes: {
      base: styles.base,
      focus: styles.focused,
    },
  };

  return (
    <FormFieldContainer error={false} disabled={isSubmitting}>
      <FormFieldLabel htmlFor={name}>{label}</FormFieldLabel>
      <StyledCardElement options={CARD_OPTIONS} onChange={handleChange} />
      <FormErrorText error={true}>{meta.value ? error : meta.error}</FormErrorText>
      <SecureConnectionTextContainer>
        <SecureConnectionText paymentTypes={['american-express', 'discover', 'master-card', 'visa']} />
      </SecureConnectionTextContainer>
    </FormFieldContainer>
  );
};

export default FormCardField;
