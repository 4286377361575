import { IconButton, Dialog, DialogTitle, Typography, styled } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { colors } from 'constants/colors';
import { useState } from 'react';
import BadgeModalContent from 'components/Badging/BadgeModalContent';

const BadgeText = styled(Typography)({
  fontWeight: 'bold',
});

const ModalTitleText = styled(Typography)({
  fontSize: '24px',
  fontWeight: 700,
  color: colors.mirage,
});

const StyledDialogTitle = styled(DialogTitle)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const ColoredCloseIcon = styled(CloseIcon)({
  color: colors.mirage,
});

const BadgeModal = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <BadgeText variant="subtitle2" onClick={handleClickOpen}>
        60 Day Money Back Guarantee
      </BadgeText>
      <Dialog open={open} onClose={handleClose}>
        <StyledDialogTitle disableTypography>
          <ModalTitleText>60 Day Money Back Guarantee</ModalTitleText>
          <IconButton aria-label="close" onClick={handleClose}>
            <ColoredCloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <BadgeModalContent />
      </Dialog>
    </div>
  );
};

export default BadgeModal;
