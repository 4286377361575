import { useMutation } from 'react-query';
import FetchAPI from 'services/FetchAPI';
import { BILLING_URL } from 'constants/urls';
import { useURLParams } from './useURLParams';
import { FlywheelBillingPayload } from 'models/Billing';
import { useEffect, useState } from 'react';
import { client, hostedFields, paypal } from 'braintree-web';
import type { HostedFields, PayPal } from 'braintree-web';

export const useBraintree = () => {
  const transactionId = useURLParams('transaction_id');
  const [hostedFieldsInstance, setHostedFieldsInstance] = useState<HostedFields | undefined>(undefined);
  const [paypalInstance, setPaypalInstance] = useState<PayPal | undefined>(undefined);
  const [braintreeError, setBraintreeError] = useState<string | undefined>('');
  const INITIALIZE_ERROR_MSG = 'Whoops! Something between us and our payment processor broke. Try again later.';

  const { mutate: addPaymentMethod } = useMutation((payload: FlywheelBillingPayload) =>
    FetchAPI.patch(`${BILLING_URL}/${transactionId}/`, payload),
  );

  // Initialize Braintree & PayPal
  useEffect(() => {
    client.create(
      {
        authorization: String(process.env.REACT_APP_BRAINTREE_TOKENIZATION_KEY),
      },
      (err, clientInstance) => {
        // Hosted fields
        hostedFields.create(
          {
            client: clientInstance,
            fields: {
              cardholderName: {
                container: '#cardholderName',
                placeholder: '',
              },
              number: {
                container: '#creditCardNumber',
                placeholder: '4111 1111 1111 1111',
              },
              cvv: {
                container: '#cvv',
                placeholder: '123',
              },
              expirationMonth: {
                selector: '#credit_card_expiration_month',
                placeholder: 'MM',
              },
              expirationYear: {
                selector: '#credit_card_expiration_year',
                placeholder: 'YYYY',
              },
              postalCode: {
                container: '#credit_card_postal_code',
                placeholder: '12345',
              },
            },
            styles: {
              input: {
                'font-family': 'Museo Sans Rounded, "Roboto", "Helvetica", "Arial", sans-serif',
                'font-size': '14px',
              },
            },
          },
          (error, hostedFieldsInstance) => {
            setHostedFieldsInstance(hostedFieldsInstance);

            if (error) {
              setBraintreeError(INITIALIZE_ERROR_MSG);
            }
          },
        );

        // PayPal
        paypal.create(
          {
            client: clientInstance,
          },
          (error, paypalInstance) => {
            setPaypalInstance(paypalInstance);

            if (error) {
              setBraintreeError(INITIALIZE_ERROR_MSG);
            }
          },
        );
      },
    );
  }, []);

  return { addPaymentMethod, hostedFieldsInstance, paypalInstance, braintreeError };
};
