import { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Stripe } from '@stripe/stripe-js';

interface Props {
  children: React.ReactNode;
}

const StripeContainer = ({ children }: Props) => {
  const [stripePromise, setStripePromise] = useState<PromiseLike<Stripe | null> | null>(null);

  useEffect(() => {
    if (typeof process.env.REACT_APP_STRIPE_PK === 'string') {
      const promise = loadStripe(String(process.env.REACT_APP_STRIPE_PK));
      setStripePromise(promise);
    }
  }, []);

  return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripeContainer;
