import { ChangeEvent, useEffect, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import FormErrorText from 'components/BillingForm/FormErrorText';
import { useCybersourceContext } from 'contexts/CybersourceContext';
import { CybersourceErrors, FormStatus } from 'models/Billing';
import images from 'react-payment-inputs/images';
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import cybersourceErrors from 'pages/shared/CybersourcePaymentPageErrors';
import { CreditCardFieldLabel, CreditCardForm, PaymentInputsWrapperStyles } from './CybersourceCardDetailsStyles';
import SecureConnectionText from '../BillingForm/SecureConnectionText';

const CybersourceCardDetails = () => {
  // react-payment-inputs handlers
  const { wrapperProps, getExpiryDateProps, getCardImageProps } = usePaymentInputs({
    errorMessages: {
      emptyCardNumber: cybersourceErrors.emptyCardNumber,
      emptyCVC: cybersourceErrors.emptySecurityCode,
      emptyExpiryDate: cybersourceErrors.emptyExpiryDate,
    },
  });
  const { isTouched, error, focused } = wrapperProps;
  // formik handling
  const [, , creditCardExpiryDateHelper] = useField('creditCardExpiryDate');
  const [creditCardExpiryDateFieldValue, setCreditCardExpiryDateFieldValue] = useState('');
  const [, , cardTypeHelper] = useField('cardType');
  const { status, errors } = useFormikContext();
  const isSubmitting = status === FormStatus.Submitting;
  const isLoading = status === FormStatus.Loading;
  // microform number & security code
  const { number, securityCode, numberLoaded, securityCodeLoaded } = useCybersourceContext();
  // field states
  const [numberFieldValue, setNumberFieldValue] = useState<any>(null);
  const [securityCodeFieldValue, setSecurityCodeFieldValue] = useState<any>(null);
  const [numberFieldFocus, setNumberFieldFocus] = useState<boolean>(false);
  const [securityCodeFieldFocus, setSecurityCodeFieldFocus] = useState<boolean>(false);
  // fields validation handling
  const numberInvalid = numberFieldValue?.valid === false && numberFieldValue?.couldBeValid === false;
  const securityCodeInvalid = securityCodeFieldValue?.valid === false && securityCodeFieldValue?.couldBeValid === false;
  const cardTypes = numberFieldValue?.card?.map((card: any) => {
    return card?.name;
  });
  const formInvalid =
    numberInvalid ||
    securityCodeInvalid ||
    numberFieldValue?.empty ||
    securityCodeFieldValue?.empty ||
    (errors as CybersourceErrors)?.creditCard ||
    (errors as CybersourceErrors)?.creditCardExpiryDate ||
    (isTouched && error);
  const formFocused = numberFieldFocus || securityCodeFieldFocus;
  //card image handling
  const displayCardType = images[`${cardTypes?.find((ct: string) => images[`${ct}`])}`];

  useEffect(() => {
    number?.on('autocomplete', (e: { expirationMonth: string; expirationYear: string }) => {
      const expirationDate = `${e?.expirationMonth} / ${e?.expirationYear.substr(-2)}`;
      setCreditCardExpiryDateFieldValue(expirationDate);
      creditCardExpiryDateHelper.setValue(expirationDate);
    });
    number?.on('change', (e: { card: { name: string }[] }) => {
      setNumberFieldValue(e);
      cardTypeHelper.setValue(e?.card);
    });
    securityCode?.on('change', (e: object) => {
      setSecurityCodeFieldValue(e);
    });
    number?.on('focus', () => {
      setNumberFieldFocus(true);
    });
    securityCode?.on('focus', () => {
      setSecurityCodeFieldFocus(true);
    });
    number?.on('blur', () => {
      setNumberFieldFocus(false);
    });
    securityCode?.on('blur', () => {
      setSecurityCodeFieldFocus(false);
    });
    // eslint-disable-next-line
  }, [number, securityCode]);

  return (
    <CreditCardForm className={`${isSubmitting ? 'cybersource-form_submitting' : ''}`} disabled={isSubmitting}>
      <CreditCardFieldLabel
        className={`'credit_card_label' ${formInvalid ? 'credit_card_label-error' : ''}`}
        htmlFor="creditCard"
      >
        Credit card number
      </CreditCardFieldLabel>
      <PaymentInputsWrapper
        {...wrapperProps}
        focused={(formFocused || focused) && !formInvalid}
        isTouched={formInvalid || isTouched}
        error={error || formInvalid}
        styles={PaymentInputsWrapperStyles(isLoading || isSubmitting)}
      >
        <svg
          {...getCardImageProps({
            images: { placeholder: displayCardType || images.placeholder },
          })}
        />
        <div id="credit-card_input" />
        {numberLoaded && securityCodeLoaded && (
          <input
            disabled={isLoading || isSubmitting}
            {...getExpiryDateProps({
              autoComplete: 'off',
              onChange: (event: ChangeEvent<HTMLSelectElement>) => {
                setCreditCardExpiryDateFieldValue(event.target.value);
                creditCardExpiryDateHelper.setValue(event.target.value);
                if (event.target.value.length >= 7) {
                  securityCode?.focus();
                }
              },
            })}
            value={creditCardExpiryDateFieldValue}
          />
        )}
        <div id="cvc_input" />
      </PaymentInputsWrapper>
      {isTouched && error && !(errors as CybersourceErrors)?.creditCardExpiryDate && (
        <FormErrorText>{error}</FormErrorText>
      )}
      {numberFieldValue?.empty && <FormErrorText>Card number is required</FormErrorText>}
      {securityCodeFieldValue?.empty && <FormErrorText>CVC is required</FormErrorText>}
      {(errors as CybersourceErrors)?.creditCard && (
        <FormErrorText>{(errors as CybersourceErrors)?.creditCard}</FormErrorText>
      )}
      {(errors as CybersourceErrors)?.creditCardExpiryDate && (
        <FormErrorText>{(errors as CybersourceErrors)?.creditCardExpiryDate}</FormErrorText>
      )}
      {numberInvalid && numberFieldValue?.empty === false && <FormErrorText>Card number is invalid.</FormErrorText>}
      {securityCodeInvalid && securityCodeFieldValue?.empty === false && <FormErrorText>CVC is invalid.</FormErrorText>}
      <SecureConnectionText paymentTypes={['american-express', 'discover', 'master-card', 'visa']} />
    </CreditCardForm>
  );
};

export default CybersourceCardDetails;
