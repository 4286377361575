import BaseModal from '../Modal';
import Button from '../Button';
import { Box } from '@material-ui/core';

interface Props {
  open: boolean;
  handleClose: (event: object, reason: string) => void;
  height?: number | string;
  width?: number;
}

const ReloadModal = ({ open, handleClose, height = 'auto', width = 375 }: Props) => {
  const style = {
    padding: '1rem',
    textAlign: 'center',
  };

  return (
    <BaseModal open={open} handleClose={handleClose} height={height} width={width}>
      <Box sx={style}>
        <div>
          Your session has expired. Please refresh the page.
          <br />
          <div style={{ marginTop: '1em' }}>
            <Button onClick={() => window.location.reload()}>Refresh</Button>
          </div>
        </div>
      </Box>
    </BaseModal>
  );
};

export default ReloadModal;
