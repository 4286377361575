import { Typography, Box, styled } from '@material-ui/core';
import CartList from './CartList';
import CartTotal from './CartTotal';
import Invoices from './Invoices';
import { QueryStatus } from 'constants/queryStatus';
import { useTransactionDetails } from 'hooks/useTransactionDetails';
import { useCart } from 'hooks/useCart';
import { colors } from 'constants/colors';

const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  gap: '20px',
  padding: '16px',
  paddingTop: '36px',
  paddingBottom: '36px',
  border: `solid 1px ${colors.polar10}`,
  borderRadius: '16px',
});

const CartSummary = () => {
  const { status, cart } = useCart();
  const { transactionDetails } = useTransactionDetails();

  if (status !== QueryStatus.Success) {
    return null;
  }

  return (
    <StyledBox>
      <Typography variant="h3">Cart</Typography>
      <CartList summary={cart} tax={cart.tax} />
      <CartTotal total={cart.total} recurrence={cart.recurrence} tax={cart.tax} />
      {transactionDetails.signup_code === 'pending_invoice' ? <Invoices /> : false}
    </StyledBox>
  );
};

export default CartSummary;
