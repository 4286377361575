export const buildDeviceIinformation = (transactionId: string) => {
  return {
    fingerprintSessionId: transactionId,
    httpBrowserColorDepth: window.screen.colorDepth.toString(),
    httpBrowserJavaEnabled: 'false',
    httpBrowserLanguage: navigator.language,
    httpBrowserJavaScriptEnabled: 'true',
    httpBrowserScreenHeight: window.screen.height.toString(),
    httpBrowserScreenWidth: window.screen.width.toString(),
    httpBrowserTimeDifference: new Date().getTimezoneOffset().toString(),
    userAgentBrowserValue: window.navigator.userAgent,
  };
};

export default buildDeviceIinformation;
