import { useFormikContext } from 'formik';
import AlertBox from './AlertBox';

interface Props {
  message: string;
}

const ErrorAlert = ({ message }: Props) => {
  const { errors, submitCount } = useFormikContext();

  if (!message && Object.keys(errors).length > 0 && submitCount > 0) {
    return <AlertBox>The highlighted fields are missing or incorrect. Please correct the information.</AlertBox>;
  }

  if (message) {
    return <AlertBox>{message}</AlertBox>;
  }

  return null;
};

export default ErrorAlert;
