import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { createTheme } from '@material-ui/core';
import { flywheelColors } from 'constants/colors';
import('fonts/museo_sans_rounded.css');
import('fonts/archer.css');

const breakpoints = createBreakpoints({});

const flywheelTheme = createTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiInput: {
      disableUnderline: true,
    },
    MuiTabs: {
      TabIndicatorProps: {
        style: {
          transition: 'none',
          display: 'none',
        },
      },
    },
  },
  palette: {
    background: {
      default: flywheelColors.gray15,
    },
    primary: {
      main: flywheelColors.gray75,
    },
    secondary: {
      main: flywheelColors.blue,
    },
    error: {
      main: flywheelColors.red,
    },
  },
  typography: {
    fontSize: 10,
    htmlFontSize: 10,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          height: '100%',
          fontSize: 10,
        },
        body: {
          height: '100%',
          display: 'flex',
          fontFamily: 'Museo Sans Rounded, "Roboto", "Helvetica", "Arial", sans-serif',
          fontSize: '1.4rem',
        },
        '#root': {
          display: 'flex',
          flex: '1',
          flexDirection: 'column',
        },
      },
    },
    MuiFormControl: {
      root: {
        gridTemplateColumns: 'auto !important',
        gridTemplateRows: 'auto !important',
        flexGrow: 1,
        marginBottom: '0 !important',
      },
    },
    MuiFormLabel: {
      root: {
        fontStyle: 'normal',
        fontSize: '1.4rem !important',
        marginBottom: '10px',
        lineHeight: 1.43,
        color: `${flywheelColors.graydark} !important`,
      },
    },
    MuiButton: {
      text: {
        fontFamily: 'Museo Sans Rounded, "Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: '1.4rem',
        fontWeight: 700,
        color: flywheelColors.bluedark,
        marginTop: '2rem',
        '&:hover': {
          backgroundColor: 'transparent',
          color: flywheelColors.bluelink,
        },
        '&$focusVisible': {
          color: flywheelColors.bluelink,
        },
      },
      contained: {
        fontFamily: 'Museo Sans Rounded, "Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: '1.4rem',
        fontWeight: 700,
        color: flywheelColors.graydark,
        backgroundColor: flywheelColors.yellow,
        border: 'none',
        boxShadow: 'none',
        borderRadius: '1.857142857em',
        padding: '0 3rem',
        height: '3.714em',
        textTransform: 'uppercase',
        lineHeight: ' 3.714em',
        wordBreakProperty: ' keep-all',
        letterSpacing: '0.02rem',
        '&:hover': {
          backgroundColor: flywheelColors.graydark50,
          color: flywheelColors.white,
          boxShadow: 'none',
        },
        '&$focusVisible': {
          backgroundColor: flywheelColors.graydark50,
          color: flywheelColors.white,
        },
      },
    },
    MuiInputBase: {
      root: {
        height: '54px',
        border: `2px solid ${flywheelColors.gray15}`,
        borderRadius: `4px`,
        transition: 'border-color .15s ease-in-out',
        '&$focused': {
          outline: 'none',
          border: `2px solid ${flywheelColors.blue}`,
        },
        '&:active': {
          outline: 'none',
          border: `2px solid ${flywheelColors.blue}`,
        },
        '&$error': {
          border: `2px solid ${flywheelColors.red}`,
        },
      },
      formControl: {
        marginTop: '0 !important',
      },
      input: {
        padding: '2rem',
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: '1.4rem',
      },
    },
    MuiNativeSelect: {
      select: {
        borderRadius: '4px',
        padding: '0 2rem',
        lineHeight: 1.43,
        height: '50px',
      },
    },
    MuiTabs: {
      flexContainer: {
        justifyContent: 'space-around',
        alignItems: 'center',
        gap: '0',
        margin: '1rem',
        [breakpoints.up('md')]: {
          height: '75px',
          margin: '1rem 1.5rem;',
        },
      },
    },
    MuiTab: {
      root: {
        fontSize: '1.4rem',
        padding: '20px !important',
        height: '75px',
        width: '100%',
        textTransform: 'none',
        transition: 'border-color .15s ease-in-out',
        border: `solid 2px ${flywheelColors.gray15}`,
        backgroundColor: flywheelColors.white,
        borderRadius: '0',
        position: 'relative',
        zIndex: 1,
        top: '0',
        overflow: 'visible',
        [breakpoints.up('md')]: {
          width: 'calc(50% + 1rem)',
        },
        '&$selected': {
          border: `2px solid ${flywheelColors.blue}`,
          borderRadius: '4px',
          zIndex: 2,
          margin: '-.5rem -1.5rem',
          width: 'calc(100% + 1rem)',
          height: 'calc(100% + 1rem)',
          [breakpoints.up('md')]: {
            width: 'calc(100% + 1rem)',
            height: 'calc(100% + 1rem)',
          },
        },

        '&.Mui-focusVisible': {
          border: `solid 2px ${flywheelColors.bluedark50}`,
        },
      },
      textColorInherit: {
        opacity: 'unset',
      },
      wrapper: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gap: '10px',
      },
    },
    MuiToolbar: {
      root: {
        '&.MuiToolbar-regular': {
          paddingLeft: '0',
          paddingRight: '0',
          minHeight: 'unset',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '1.4rem',
        lineHeight: 1.43,
        color: flywheelColors.gray,
        backgroundColor: flywheelColors.white,
        borderRadius: '4px',
        boxShadow: '0 0 2px 0 rgb(38 39 39 / 35%)',
        padding: '0.75em',
        textAlign: 'center',
      },
      arrow: {
        color: flywheelColors.white,
        '&::before': {
          border: `1px solid ${flywheelColors.gray15}`,
        },
      },
    },
  },
});

flywheelTheme.typography.fontFamily = 'Museo Sans Rounded, "Roboto", "Helvetica", "Arial", sans-serif';

flywheelTheme.typography.body1 = {
  fontSize: '1.4rem',
};

flywheelTheme.typography.h1 = {
  color: flywheelColors.graydark,
  letterSpacing: '0.02em',
  fontWeight: 500,
  marginBottom: '1rem',
  [flywheelTheme.breakpoints.down('md')]: {
    fontSize: '3.2rem',
    lineHeight: 1.1875,
  },
  [flywheelTheme.breakpoints.up('md')]: {
    fontSize: '4.2rem',
    lineHeight: 1.142,
  },
};

flywheelTheme.typography.h2 = {
  color: flywheelColors.graydark,
  letterSpacing: '0.01em',
  fontWeight: 500,
  [flywheelTheme.breakpoints.down('md')]: {
    fontSize: '2rem',
    lineHeight: 1.3,
  },
  [flywheelTheme.breakpoints.up('md')]: {
    fontSize: '2.4rem',
    lineHeight: 1.142,
  },
};

flywheelTheme.typography.h3 = {
  fontFamily: 'Archer SSm A, Archer SSm B, serif',
  color: flywheelColors.gray,
  letterSpacing: 0,
  fontWeight: 500,
  [flywheelTheme.breakpoints.down('md')]: {
    fontSize: '1.8rem',
    lineHeight: 1.222,
  },
  [flywheelTheme.breakpoints.up('md')]: {
    fontSize: '2.4rem',
    lineHeight: 1.333,
  },
};

flywheelTheme.typography.h4 = {
  color: flywheelColors.graydark,
  letterSpacing: '0.02em',
  fontWeight: 500,
  [flywheelTheme.breakpoints.down('md')]: {
    fontSize: '1.6rem',
    lineHeight: 1.375,
  },
  [flywheelTheme.breakpoints.up('md')]: {
    fontSize: '1.8rem',
    lineHeight: 1.333,
  },
};

flywheelTheme.typography.h6 = {
  color: flywheelColors.gray,
  letterSpacing: '0.04em',
  fontWeight: 700,
  textTransform: 'uppercase',
  [flywheelTheme.breakpoints.down('md')]: {
    fontSize: '1.2rem',
    lineHeight: 1.5,
  },
  [flywheelTheme.breakpoints.up('md')]: {
    fontSize: '1.4rem',
    lineHeight: 1.42,
  },
};

export default flywheelTheme;
