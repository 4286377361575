import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import { styled } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import * as React from 'react';
import { colors } from 'constants/colors';

interface IInfoTipProps {
  size?: number;
  iconButtonProps?: IconButtonProps;
  iconType?: '?' | 'i';
}

const InfoTip: React.FC<Omit<TooltipProps, 'children'> & IInfoTipProps> = ({
  size,
  iconButtonProps,
  iconType,
  ...props
}) => {
  const StyledTooltip = styled(Tooltip)({
    padding: '8px 16px',
    lineHeight: '24px',
  });

  const StyledIconButton = styled(IconButton)({
    padding: 0,
    color: colors.mirage,
    '&:hover, &.Mui-focusVisible': {
      color: colors.royal,
    },
  });

  const StyledSvgIcon = styled(SvgIcon)({
    fontSize: size,
  });

  const getIcon = (): React.ReactElement => {
    if (iconType === '?') {
      return (
        <StyledSvgIcon>
          <path d="M12 0C5.4 0 0 5.4 0 12C0 18.6 5.4 24 12 24C18.6 24 24 18.6 24 12C24 5.4 18.6 0 12 0ZM12 19.5C11.1 19.5 10.5 18.9 10.5 18C10.5 17.1 11.1 16.5 12 16.5C12.9 16.5 13.5 17.1 13.5 18C13.5 18.9 12.9 19.5 12 19.5ZM14.25 12.6C13.5 13.05 13.5 13.2 13.5 13.5C13.5 14.3284 12.8284 15 12 15C11.1716 15 10.5 14.3284 10.5 13.5C10.5 11.55 11.7 10.65 12.6 10.05C13.35 9.6 13.5 9.45 13.5 9C13.5 8.1 12.9 7.5 12 7.5C11.4 7.5 10.95 7.8 10.65 8.25L10.6361 8.27508C10.2325 9.00148 9.30984 9.25285 8.59361 8.83153C7.90188 8.42464 7.66095 7.54029 8.05069 6.83876L8.1 6.75C8.85 5.4 10.35 4.5 12 4.5C14.55 4.5 16.5 6.45 16.5 9C16.5 11.1 15.15 12 14.25 12.6Z" />
        </StyledSvgIcon>
      );
    }
    return (
      <StyledSvgIcon viewBox="0 0 16 16">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM6.66667 8C6.66667 7.26362 7.26362 6.66667 8 6.66667C8.73638 6.66667 9.33333 7.26362 9.33333 8V12.6667C9.33333 13.403 8.73638 14 8 14C7.26362 14 6.66667 13.403 6.66667 12.6667V8ZM8 5.33333C8.73638 5.33333 9.33333 4.73638 9.33333 4C9.33333 3.26362 8.73638 2.66667 8 2.66667C7.26362 2.66667 6.66667 3.26362 6.66667 4C6.66667 4.73638 7.26362 5.33333 8 5.33333Z"
        />
      </StyledSvgIcon>
    );
  };

  return (
    <StyledTooltip {...props}>
      <StyledIconButton {...iconButtonProps}>{getIcon()}</StyledIconButton>
    </StyledTooltip>
  );
};

InfoTip.defaultProps = {
  iconType: '?',
  placement: 'bottom',
  size: 24,
};

export default InfoTip;
