import { Grid, styled, Button, Typography, Box, InputBase, FormLabel, Tooltip, IconButton } from '@material-ui/core';
import { flywheelColors } from 'constants/colors';
import { useEffect, useState } from 'react';
import type { ReactNode } from 'react';
import BraintreePaymentCardTypes from './BraintreePaymentCardTypes';
import { useCountries } from 'hooks/useCountries';
import { FlywheelBillingPayload } from 'models/Billing';
import { useBraintree } from 'hooks/useBraintree';
import { useTransactionDetails } from 'hooks/useTransactionDetails';
import BraintreeCountrySelector from './FlywheelCountrySelector';
import AlertBox from 'components/AlertBox';
import PaymentTypeTabs, { PaymentType, PaymentTypeTabPanel } from './PaymentTypeTabs';
import paypalButtonImage from 'assets/fw-paypal-button.png';
import PaypalCard from './PaypalCard';
import type { BraintreeError, PayPalTokenizePayload } from 'braintree-web';
import type {
  HostedFieldsEvent,
  HostedFieldsFieldDataFields,
  HostedFieldsHostedFieldsFieldData,
  HostedFieldsHostedFieldsFieldName,
} from 'braintree-web/modules/hosted-fields';
import spinnerIcon from 'assets/fw-spinner.svg';
import InfoTipIcon from 'assets/fw-info';

const Form = styled('form')({
  '& .is-invalid, .braintree-hosted-fields-focused.is-invalid': {
    border: `2px solid ${flywheelColors.red}`,
  },

  '& .braintree-hosted-fields-focused': {
    border: `2px solid ${flywheelColors.blue}`,
  },
});

const InnerFormContainer = styled(Grid)({
  padding: '3rem',
});

const ButtonsContainer = styled(Grid)(({ theme }) => ({
  marginTop: '2rem',
  padding: '3rem',
  borderTop: `1px solid ${flywheelColors.gray15}`,
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row-reverse',
    '& .MuiButton-text': {
      marginTop: '0',
    },
  },
}));

const MuiInputField = styled(InputBase)({
  height: '54px',
  width: 'calc(100% + 2rem + 2px)',
});

// Braintree will replace this div with an actual input field once loaded.
const BraintreeInputField = styled('div')({
  height: '50px',
  width: '100%',
  padding: '5rem 0 0',
  margin: '0 auto',
  position: 'relative',
  transition: 'border-color .15s ease-in-out',
  border: `2px solid ${flywheelColors.gray15}`,
  borderRadius: '4px',
  '& iframe': {
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    padding: '0 2rem',
  },
  '&[aria-disabled="true"]': {
    opacity: '0.55',
    pointerEvents: 'none',
  },
});

const FormFieldLabel = styled(FormLabel)({
  width: '100%',
  textAlign: 'left',
});

const StyledIconButton = styled(IconButton)({
  color: flywheelColors.gray75,
  padding: '0 .5rem',
  verticalAlign: 'top',
  minWidth: 'auto',
  fill: flywheelColors.gray75,
  '&.Mui-focusVisible svg path, &:hover svg path': {
    fill: flywheelColors.graydark,
  },
});

const PayPalButton = styled(Button)({
  border: '2px solid #019cde',
  background: '#019cde',
  borderRadius: '7px',
  marginTop: 0,
  padding: 0,
  '&.Mui-focusVisible': {
    border: `2px solid ${flywheelColors.bluedark50}`,
    borderRadius: '7px',
  },
});

const PaypalButtonImage = styled('img')({
  height: '44px',
  width: '115px',
  cursor: 'pointer',
  '&:hover': {
    filter: 'brightness(90%)',
  },
});

const ErrorText = styled('label')({
  marginTop: '5px',
  marginLeft: '2px',
  color: flywheelColors.red,
  textAlign: 'left',
});

const FieldContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  flex: '1',
  alignItems: 'flex-start',
}));

const MultiFieldContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flex: '1',
  justifyContent: 'space-evenly',
  alignItems: 'flex-start',
  gridGap: '20px',
  // Breakpoint which triggers on smaller screens
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gridGap: '25px',
    alignItems: 'unset',
  },
}));

const FlywheelForm = () => {
  const { addPaymentMethod, hostedFieldsInstance, braintreeError, paypalInstance } = useBraintree();
  const { transactionDetails } = useTransactionDetails();
  const { countries } = useCountries();
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>('US');
  const [nickname, setNickname] = useState<string>('');
  const [selectedCardType, setSelectedCardType] = useState('');
  const [selectedTabIndex, setSelectedTabIndex] = useState(PaymentType.CREDIT_CARD);
  const [paypalInfo, setPaypalInfo] = useState<PayPalTokenizePayload | undefined>(undefined);
  const [fieldsState, setFieldsState] = useState<HostedFieldsFieldDataFields>();
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isBraintreeReady, setIsBraintreeReady] = useState<boolean>(false);
  const [isUserReady, setIsUserReady] = useState<boolean>(false);
  const loadingIcon = isSubmitting ? <img src={spinnerIcon} alt="Submitting payment method" /> : null;
  const submitButtonText = isSubmitting ? 'Processing' : 'Add Payment Method';
  const shouldDisable = isSubmitting || !isBraintreeReady;
  const submitTooltipText = selectedTabIndex
    ? 'Add your Paypal account to continue.'
    : 'Add your Credit Card information to continue.';

  useEffect(() => {
    if (braintreeError) {
      setErrorMessage(braintreeError);
    }
  }, [braintreeError]);

  // Check if braintree and paypal have been initialized
  useEffect(() => {
    if (hostedFieldsInstance && paypalInstance) {
      setIsBraintreeReady(true);
    } else {
      setIsBraintreeReady(false);
    }
  }, [hostedFieldsInstance, paypalInstance]);

  // Get fields state as soon as they are initialized
  useEffect(() => {
    if (hostedFieldsInstance) {
      setFieldsState(hostedFieldsInstance?.getState()?.fields);
    }
  }, [hostedFieldsInstance]);

  const onHostedFieldsEvent = (event: HostedFieldsEvent) => {
    // Update to the latest fields state
    setFieldsState(event.fields);
    // Remove any previously added classes
    const field = event.fields[event.emittedBy];
    field.container.classList.remove('is-invalid');

    if (isFormValid(event.fields)) {
      // Hide global alert box once the user has addressed all invalid fields
      setIsUserReady(true);
      setErrorMessage('');
      // Don't bother go any further
      return;
    }

    // Add invalid class if field is empty or invalid
    if (!isValidField(field) || isEmptyField(field)) {
      setIsUserReady(false);
      return field.container.classList.add('is-invalid');
    }
  };

  hostedFieldsInstance?.on('validityChange', (event) => {
    onHostedFieldsEvent(event);
  });

  hostedFieldsInstance?.on('empty', (event) => {
    onHostedFieldsEvent(event);
  });

  hostedFieldsInstance?.on('notEmpty', (event) => {
    onHostedFieldsEvent(event);
  });

  hostedFieldsInstance?.on('inputSubmitRequest', () => {
    submitForm(null);
  });

  hostedFieldsInstance?.on('cardTypeChange', (event) => {
    // Don't bother set the selected card type if multiple are matched.
    if (event?.cards?.length === 1) {
      setSelectedCardType(event?.cards[0].type);
    } else {
      setSelectedCardType('');
    }
  });

  const patchToSignupAPI = (payload: FlywheelBillingPayload) => {
    addPaymentMethod(payload, {
      onSuccess: () => {
        window.location.href = transactionDetails?.redirect_url;
      },
      onError: (error: any) => {
        // Display error alert box
        if (error?.user_actionable === true) {
          setErrorMessage(error?.message);
        } else {
          setErrorMessage('Whoops! Please double check that this information is correct.');
        }
        setIsSubmitting(false);
        disableBraintreeFields(false);
      },
    });
  };

  const getBraintreeErrorMessage = (tokenizeErr: BraintreeError) => {
    let errorMessage = '';

    switch (tokenizeErr?.code) {
      case 'HOSTED_FIELDS_FIELDS_EMPTY':
        errorMessage = 'Uh oh, all fields are empty! Please fill out the form.';
        break;
      case 'HOSTED_FIELDS_FIELDS_INVALID':
        errorMessage = 'Whoops! The highlighted fields are missing or incorrect.';
        break;
      case 'HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE':
        errorMessage = 'Whoops! You already added this payment method.';
        break;
      case 'HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED':
        errorMessage = 'Whoops! Your CVV code did not match.';
        break;
      case 'HOSTED_FIELDS_FAILED_TOKENIZATION':
        errorMessage = 'Please make sure this card is valid.';
        break;
      case 'HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR':
        errorMessage = 'Uh oh! There was a network error. Please try again later.';
        break;
      default:
        if (tokenizeErr?.message) {
          errorMessage = `Uh oh! Something wasn't right. ${tokenizeErr.message}.`;
        } else {
          errorMessage = `Uh oh! Something wasn't right.`;
        }
    }

    return errorMessage;
  };

  const onPaypalButtonClicked = () => {
    // Because tokenization opens a popup, this has to be called as a result of
    // a customer action, like clicking a button.
    paypalInstance?.tokenize(
      {
        flow: 'vault',
      },
      (tokenizeErr, paypalPayload) => {
        if (tokenizeErr) {
          return;
        }

        setPaypalInfo(paypalPayload);
        setIsUserReady(true);
        setErrorMessage('');
      },
    );
  };

  const onNicknameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNickname(event.target.value);
  };

  const onCountryChanged = (country: string) => {
    setSelectedCountryCode(country);
  };

  const onPaymentTypeTabChanged = (tabIndex: number) => {
    setSelectedTabIndex(tabIndex);
    setErrorMessage('');
  };

  const submitForm = (event: React.FormEvent<HTMLFormElement> | null) => {
    // Prevent the page from reloading
    event?.preventDefault();
    setHasSubmitted(true);
    setIsSubmitting(true);
    disableBraintreeFields(true);

    if (selectedTabIndex === PaymentType.CREDIT_CARD) {
      hostedFieldsInstance?.tokenize(
        {
          billingAddress: { countryCodeAlpha2: selectedCountryCode },
        },
        (tokenizeErr, braintreePayload) => {
          if (tokenizeErr) {
            // Display a friendly error message
            const errorMessage = getBraintreeErrorMessage(tokenizeErr);
            setErrorMessage(errorMessage);
            setIsSubmitting(false);
            disableBraintreeFields(false);

            // Get the current state of fields to determine if they're valid etc.
            const fieldsState = hostedFieldsInstance?.getState()?.fields;
            setFieldsState(fieldsState);

            // Add a class to each invalid braintree field container to show a red border.
            for (const field of Object.values(fieldsState)) {
              if (!field.isValid) {
                field.container.classList.add('is-invalid');
              }
            }
            return;
          }

          const payload = {
            cc_token: braintreePayload?.nonce,
            nickname: nickname,
          };

          patchToSignupAPI(payload);
        },
      );
    }

    if (selectedTabIndex === PaymentType.PAYPAL) {
      const payload: FlywheelBillingPayload = {
        cc_token: paypalInfo?.nonce,
        nickname: nickname,
      };

      patchToSignupAPI(payload);
    }
  };

  interface ErrorTextProps {
    fieldName: HostedFieldsHostedFieldsFieldName;
    children: ReactNode;
  }

  const RequiredErrorText = ({ fieldName, children }: ErrorTextProps) => {
    if (!fieldName || !fieldsState) {
      return null;
    }

    const field = fieldsState[fieldName];

    // Only show an error if the field is empty and the user attempted to submit the form.
    if (isEmptyField(field)) {
      return <ErrorText>{children}</ErrorText>;
    }

    return null;
  };

  const InvalidErrorText = ({ fieldName, children }: ErrorTextProps) => {
    if (!fieldName || !fieldsState) {
      return null;
    }

    const field = fieldsState[fieldName];

    if (!isValidField(field)) {
      return <ErrorText>{children}</ErrorText>;
    }

    return null;
  };

  const isValidField = (field: HostedFieldsHostedFieldsFieldData) => {
    // Field is not empty and has no potential
    // to be valid e.g entering 0 into the credit card field.
    if (field?.isEmpty === false && field?.isPotentiallyValid === false) {
      return false;
    }

    // User has submitted the form, but the field is still invalid, so
    // show them an error regardless of its potential validity.
    if (field?.isEmpty === false && field?.isValid === false && hasSubmitted) {
      return false;
    }

    return true;
  };

  const isEmptyField = (field: HostedFieldsHostedFieldsFieldData) => {
    // Field is empty and the user attempted to submit the form.
    return field?.isEmpty === true && hasSubmitted;
  };

  const disableBraintreeFields = (shouldDisable: boolean) => {
    if (!fieldsState) {
      return;
    }

    for (const fieldName of Object.keys(fieldsState)) {
      if (shouldDisable) {
        // @ts-ignore
        hostedFieldsInstance?.setAttribute({
          field: fieldName,
          attribute: 'disabled',
          value: 'true',
        });
      } else {
        // @ts-ignore
        hostedFieldsInstance?.removeAttribute({
          field: fieldName,
          attribute: 'disabled',
        });
      }
    }
  };

  const isFormValid = (fields: HostedFieldsFieldDataFields) => {
    // @ts-ignore
    return Object.keys(fields).every((key: HostedFieldsHostedFieldsFieldName) => {
      return fields[key].isValid;
    });
  };

  return (
    <>
      <Form onSubmit={submitForm}>
        <InnerFormContainer>
          <Typography variant="h2" component="h3" align="left">
            Payment information
          </Typography>
          <Box mt={3}>
            <PaymentTypeTabs
              onTabChanged={onPaymentTypeTabChanged}
              selectedTabIndex={selectedTabIndex}
              disabled={shouldDisable}
            />

            {errorMessage && <AlertBox>{errorMessage}</AlertBox>}
            <PaymentTypeTabPanel currentTabIndex={selectedTabIndex} tabIndex={PaymentType.CREDIT_CARD}>
              <Grid container spacing={4} direction="row">
                <Grid container item xs={12} md={12}>
                  <FormFieldLabel htmlFor="cardholderName">Cardholder's name</FormFieldLabel>
                  <BraintreeInputField id="cardholderName" aria-disabled={shouldDisable} />
                  <RequiredErrorText fieldName="cardholderName">Cardholder's name is required.</RequiredErrorText>
                  <InvalidErrorText fieldName="cardholderName">
                    Cardholder's name cannot contain only numbers.
                  </InvalidErrorText>
                </Grid>
                <Grid container item xs={12} md={8}>
                  <FormFieldLabel htmlFor="creditCardNumber">Card number</FormFieldLabel>
                  <BraintreeInputField id="creditCardNumber" aria-disabled={shouldDisable} />
                  <RequiredErrorText fieldName="number">Card number is required</RequiredErrorText>
                  <InvalidErrorText fieldName="number">Card number is invalid.</InvalidErrorText>
                </Grid>
                <BraintreePaymentCardTypes selectedCardType={selectedCardType} />
                <Grid container item>
                  <MultiFieldContainer>
                    <FieldContainer>
                      <FormFieldLabel htmlFor="credit_card_expiration_month">Exp. month (MM)</FormFieldLabel>
                      <BraintreeInputField id="credit_card_expiration_month" aria-disabled={shouldDisable} />
                      <RequiredErrorText fieldName="expirationMonth">Month is required.</RequiredErrorText>
                      <InvalidErrorText fieldName="expirationMonth">Month is invalid.</InvalidErrorText>
                    </FieldContainer>
                    <FieldContainer>
                      <FormFieldLabel htmlFor="credit_card_expiration_year">Exp. year (YYYY)</FormFieldLabel>
                      <BraintreeInputField id="credit_card_expiration_year" aria-disabled={shouldDisable} />
                      <RequiredErrorText fieldName="expirationYear">Year is required.</RequiredErrorText>
                      <InvalidErrorText fieldName="expirationYear">Year is invalid.</InvalidErrorText>
                    </FieldContainer>
                    <FieldContainer>
                      <FormFieldLabel htmlFor="cvv">CVV</FormFieldLabel>
                      <BraintreeInputField id="cvv" aria-disabled={shouldDisable} />
                      <RequiredErrorText fieldName="cvv">CVV is required.</RequiredErrorText>
                      <InvalidErrorText fieldName="cvv">CVV is invalid.</InvalidErrorText>
                    </FieldContainer>
                  </MultiFieldContainer>
                </Grid>
                <Grid container item>
                  <MultiFieldContainer>
                    <FieldContainer>
                      <FormFieldLabel htmlFor="credit_card_postal_code">Zip/Postal Code</FormFieldLabel>
                      <BraintreeInputField id="credit_card_postal_code" aria-disabled={shouldDisable} />
                      <RequiredErrorText fieldName="postalCode">Zip/Postal Code is required.</RequiredErrorText>
                      <InvalidErrorText fieldName="postalCode">
                        Zip/Postal Code must be greater than 3 characters.
                      </InvalidErrorText>
                    </FieldContainer>
                    <FieldContainer aria-disabled={shouldDisable}>
                      <BraintreeCountrySelector
                        countries={countries}
                        onCountryChanged={onCountryChanged}
                        defaultCountryCode={selectedCountryCode}
                        disabled={shouldDisable}
                      />
                    </FieldContainer>
                  </MultiFieldContainer>
                </Grid>
              </Grid>
            </PaymentTypeTabPanel>
            <PaymentTypeTabPanel currentTabIndex={selectedTabIndex} tabIndex={PaymentType.PAYPAL}>
              {paypalInfo?.details?.email ? (
                <PaypalCard email={paypalInfo?.details?.email} />
              ) : (
                <PayPalButton type="button" onClick={onPaypalButtonClicked}>
                  <PaypalButtonImage src={paypalButtonImage} />
                </PayPalButton>
              )}
            </PaymentTypeTabPanel>
          </Box>

          {/* Nickname field is shared between both tabs */}
          <Grid container item xs={12}>
            <FormFieldLabel htmlFor="nickname">
              Payment method nickname (optional)
              <Tooltip
                title="This nickname can be used to differentiate multiple payment methods (e.g. home, work)."
                placement="bottom"
                arrow
              >
                <StyledIconButton>
                  <InfoTipIcon />
                </StyledIconButton>
              </Tooltip>
            </FormFieldLabel>
            <MuiInputField
              id="nickname"
              type="text"
              inputProps={{
                maxLength: 64,
              }}
              value={nickname}
              onChange={onNicknameChanged}
              disabled={shouldDisable}
            />
          </Grid>
        </InnerFormContainer>
        <ButtonsContainer container item justifyContent="space-between">
          <Tooltip title={isUserReady ? '' : submitTooltipText} placement="top" arrow>
            <span>
              <Button type="submit" variant="contained" disabled={shouldDisable || !isUserReady} endIcon={loadingIcon}>
                {submitButtonText}
              </Button>
            </span>
          </Tooltip>
          {transactionDetails?.cancel_url && (
            <Button
              type="button"
              variant="text"
              href={transactionDetails?.cancel_url}
              role="button"
              disabled={shouldDisable}
            >
              Cancel
            </Button>
          )}
        </ButtonsContainer>
      </Form>
    </>
  );
};

export default FlywheelForm;
