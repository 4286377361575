import { useQuery } from 'react-query';
import FetchAPI from 'services/FetchAPI';
import { BILLING_INFO_URL } from 'constants/urls';
import { BillingInfo, CybersourceBillingInfo, BraintreeBillingInfo } from 'models/BillingInfo';
import { useURLParams } from 'hooks/useURLParams';

// This hook is used retrieve billing information which we can use to pre-populate the form.
export const useBillingInfo = () => {
  const transactionId = useURLParams('transaction_id');
  const salesOrder = useURLParams('sales_order');

  const { status, data } = useQuery(
    'billingInfoData',
    () => {
      return FetchAPI.get(`${BILLING_INFO_URL}/${transactionId}/`);
    },
    { enabled: !salesOrder },
  );

  const billingInfo: BillingInfo | CybersourceBillingInfo | BraintreeBillingInfo = data || null;

  return { status, billingInfo };
};
