import { AppBar, Toolbar, styled, Container } from '@material-ui/core';
import logoWhite from 'assets/wpe-full-logo-white-2024.svg';
import logoBlack from 'assets/wpe-full-logo-black-2024.svg';
import logoFW from 'assets/fw-mark-logo-white.svg';
import { useTransactionDetails } from 'hooks/useTransactionDetails';

const WPELink = styled('a')({
  height: '155px',
  width: '250px',
});

const WPELogo = styled('img')({
  height: '155px',
  width: '250px',
});

const FlywheelLink = styled('a')(({ theme }) => ({
  height: '85px',
  width: '85px',
  display: 'flex',
  alignItems: 'center',
  // Breakpoint which triggers on smaller screens
  [theme.breakpoints.down('sm')]: {
    width: '47px',
    height: '47px',
  },
}));

const FlywheelLogo = styled('img')(({ theme }) => ({
  height: '72.2px',
  width: '72.2px',
  // Breakpoint which triggers on smaller screens
  [theme.breakpoints.down('sm')]: {
    width: '45px',
    height: '45px',
  },
}));

const VerticalLine = styled('div')(({ theme }) => ({
  borderLeft: '2px solid hsl(0deg 0% 100% / 20%)',
  padding: '22px 0',
  marginLeft: '10px',
  // Breakpoint which triggers on smaller screens
  [theme.breakpoints.down('sm')]: {
    padding: '0',
    margin: '0',
    height: '100%',
  },
}));

const Header = () => {
  const { transactionDetails } = useTransactionDetails();

  if (!transactionDetails) {
    return null;
  }

  return (
    <AppBar position="static" color="secondary" elevation={0}>
      <Toolbar>
        {!transactionDetails.update_flow && transactionDetails.billing_system !== 'flywheel' ? (
          // WP Engine Signup Flow
          <Container>
            <WPELink href="https://wpengine.com">
              <WPELogo src={logoWhite} alt="WP Engine Logo" />
            </WPELink>
          </Container>
        ) : !transactionDetails.update_flow && transactionDetails.billing_system === 'flywheel' ? (
          // Flywheel Flow
          <FlywheelLink href="https://getflywheel.com">
            <FlywheelLogo src={logoFW} alt="Flywheel Logo" />
            <VerticalLine />
          </FlywheelLink>
        ) : (
          // WP Engine Update Payment Flow
          <WPELink href="https://wpengine.com">
            <WPELogo src={logoBlack} alt="WP Engine Logo" />
          </WPELink>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
