import { NativeSelect } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import FormErrorText from './FormErrorText';
import FormFieldLabel from './FormFieldLabel';
import FormFieldContainer from './FormFieldContainer';
import { FormStatus } from 'models/Billing';
import { DropdownOption } from 'models/DropdownOption';

interface Props {
  label: string;
  name: string;
  options: DropdownOption[];
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
}

const FormSelectField = ({ label, name, options, onChange }: Props) => {
  const { status } = useFormikContext();
  const isSubmitting = status === FormStatus.Submitting;
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(event.target.value);

    if (onChange) {
      onChange(event);
    }
  };

  return (
    <FormFieldContainer error={meta.touched && Boolean(meta.error)} disabled={isSubmitting}>
      <FormFieldLabel htmlFor={name}>{label}</FormFieldLabel>
      <NativeSelect id={name} {...field} onChange={handleChange}>
        {options?.map((option: DropdownOption) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </NativeSelect>
      <FormErrorText>{meta.touched && meta.error}</FormErrorText>
    </FormFieldContainer>
  );
};

export default FormSelectField;
