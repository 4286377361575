import { useFormikContext } from 'formik';
import Button from './Button';
import BackIcon from '@material-ui/icons/NavigateBefore';
import { FormStatus } from 'models/Billing';

interface Props {
  portalInvoicePageUrl?: string;
}

const CancelButton = ({ portalInvoicePageUrl }: Props) => {
  const { status } = useFormikContext();
  const isSubmitting = status === FormStatus.Submitting;

  return (
    <Button kind="tertiary" href={portalInvoicePageUrl} disabled={isSubmitting} startIcon={<BackIcon />}>
      Cancel
    </Button>
  );
};

export default CancelButton;
