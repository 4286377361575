import CreditCardIcon, { CreditCardBrand } from 'assets/CreditCardIcon';
import { Fragment } from 'react';
import { Box, styled } from '@material-ui/core';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontSize: '34px',
  maxHeight: '60px',
  padding: '0 15px',
  // Breakpoint which triggers on medium screens and up
  [theme.breakpoints.up('md')]: {
    marginTop: '62px',
  },
}));

interface StyledCardIconProps {
  selected: boolean;
}

const StyledCardIcon = styled(CreditCardIcon)((props: StyledCardIconProps) => ({
  fontSize: '34px',
  transition: 'all .15s ease-in-out',
  width: '40px',
  opacity: props.selected ? 1 : 0.2,
  filter: `grayscale(${props.selected ? '0' : '1'})`,
}));

interface Props {
  selectedCardType: string;
}

const BraintreePaymentCardTypes = ({ selectedCardType }: Props) => {
  const paymentTypes: CreditCardBrand[] = ['visa', 'american-express', 'master-card', 'discover'];

  return (
    <StyledBox>
      {paymentTypes.map((cardType, index) => {
        return (
          <Fragment key={cardType + index}>
            <StyledCardIcon type={cardType} selected={selectedCardType === cardType} titleAccess={cardType} />
          </Fragment>
        );
      })}
    </StyledBox>
  );
};

export default BraintreePaymentCardTypes;
