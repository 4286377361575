import { useQuery } from 'react-query';
import FetchAPI from 'services/FetchAPI';
import { SALES_ORDER_TRANSACTION_DETAILS_URL } from 'constants/urls';
import { useURLParams } from 'hooks/useURLParams';

export const useSalesOrderTransactionId = () => {
  const sales_order = useURLParams('sales_order');

  const { status, data } = useQuery(
    'transactionSalesOrderDetailsData',
    () => {
      return FetchAPI.get(`${SALES_ORDER_TRANSACTION_DETAILS_URL}/${sales_order}/`);
    },
    { enabled: !!sales_order, retry: false },
  );

  const transactionId: string | null = data || null;

  return { status, transactionId };
};
