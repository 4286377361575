import { ThemeOptions } from '@material-ui/core';
import { colors } from 'constants/colors';

const updatePaymentTheme: ThemeOptions = {
  palette: {
    background: {
      default: colors.white,
    },
    primary: {
      main: colors.royal,
    },
    secondary: {
      main: colors.white,
    },
  },
  overrides: {
    // There is a confusing misalignment in the mapping of terminology between
    // Material UI buttons and Unicorn buttons.
    // Therefore, here's a table to make the mapping easier to understand:

    //  MUI         |   UNICORN
    //  -------------------------
    //  Default     |   Secondary
    //  Primary     |   Primary
    //  Secondary   |   Destruct
    //  Outlined    |   Tertiary
    //  Disabled    |   Disabled
    MuiContainer: {
      root: {
        marginTop: '30px',
      },
    },
    MuiButton: {
      root: {
        borderRadius: '2px',
        fontFamily: 'Open Sans',
        fontSize: '0.875rem',
        fontWeight: 600,
        padding: '8px 24px',
        height: '40px',
        transition: 'none',
        '&:active': {
          boxShadow: 'inset 0px 4px 4px rgba(0, 40, 56, 0.4)',
        },
        '&$focusVisible': {
          outline: `2px solid ${colors.royal}`,
          outlineOffset: '2px',
        },
        '&$disabled': {
          backgroundColor: colors.polar10,
          color: colors.polar25,
        },
      },
      // MUI Default / UNICORN Secondary
      contained: {
        backgroundColor: colors.mirage,
        border: '1px solid transparent',
        boxShadow: 'none',
        color: colors.white,
        '&:active': {
          boxShadow: 'inset 0px 4px 4px rgba(0, 40, 56, 0.4)',
        },
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: colors.mirageShade,
          color: colors.white,
        },
        '&$focusVisible': {
          boxShadow: 'none',
          backgroundColor: colors.mirageTint,
          color: colors.white,
          textDecoration: 'none',
        },
        '&$disabled': {
          backgroundColor: colors.polar10,
          color: colors.white,
        },
      },
      // MUI Primary / UNICORN Primary
      containedPrimary: {
        backgroundColor: colors.royal,
        '&:hover': {
          backgroundColor: colors.royalShade,
        },
        '&$focusVisible': {
          backgroundColor: colors.royal,
        },
      },
      // MUI Secondary / UNICORN Destruct
      containedSecondary: {
        backgroundColor: colors.cherry,
        '&:hover': {
          backgroundColor: colors.cherryShade,
        },
      },
      // MUI Outlined / UNICORN Tertiary
      outlined: {
        backgroundColor: colors.white,
        border: `2px solid ${colors.mirage}`,
        color: colors.mirage,
        padding: '8px 24px',
        '&:hover': {
          backgroundColor: colors.white,
          borderColor: colors.royal,
          color: colors.royal,
        },
        '&$focusVisible': {
          borderColor: colors.royal,
          color: colors.royal,
        },
      },
    },
    MuiToolbar: {
      root: {
        boxShadow: `inset -1px -1px 0px ${colors.polar10}`,
      },
    },
  },
  typography: {
    fontFamily: 'Inter',
    h1: {
      fontFamily: 'Lora',
      color: colors.navy,
    },
    h2: {
      fontFamily: 'Lora',
      color: colors.navy,
    },
    h3: {
      fontFamily: 'Lora',
      color: colors.navy,
      fontWeight: 500,
    },
    h4: {
      fontFamily: 'Inter',
      color: colors.navy,
    },
    h5: {
      fontFamily: 'Inter',
      color: colors.navy,
    },
    body1: {
      color: colors.navy,
    },
  },
};
export default updatePaymentTheme;
