import { CssBaseline, ThemeProvider, styled, Box, createTheme, Theme } from '@material-ui/core';
import Header from 'components/Header';
import SignupPaymentPage from 'pages/signup-payment/SignupPaymentPage';
import FlywheelAddPaymentMethodPage from 'pages/flywheel-add-payment/FlywheelAddPaymentMethodPage';
import UpdatePaymentInfoPage from 'pages/update-payment-info/UpdatePaymentInfoPage';
import ErrorPage from 'pages/error/ErrorPage';
import signupPaymentTheme from 'themes/signupPaymentTheme';
import flywheelTheme from 'themes/flywheelTheme';
import updatePaymentInfoTheme from 'themes/updatePaymentTheme';
import commonTheme from 'themes/commonTheme';
import { useTransactionDetails } from 'hooks/useTransactionDetails';
import { QueryStatus } from 'constants/queryStatus';
import { useSalesOrderTransactionId } from 'hooks/useSalesOrder';

let defaultTheme: Theme;

const AppContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  paddingBottom: '30px',
});

function App() {
  const { status: transactionDetailsStatus, transactionDetails } = useTransactionDetails();
  const { status: salesOrderStatus, transactionId } = useSalesOrderTransactionId();

  if (salesOrderStatus === QueryStatus.Success && transactionId) {
    window.location.href = `/?transaction_id=${transactionId}`;
  }

  if (transactionDetailsStatus === QueryStatus.Error) {
    return (
      <ErrorPage message="An error occurred while attempt to retrieve the transaction id. Please reach out to your sales agent for support." />
    );
  }

  if (salesOrderStatus === QueryStatus.Error) {
    return (
      <ErrorPage message="An error occurred while attempt to retrieve the transaction id. Please reach out to your sales agent for support." />
    );
  }

  if (transactionDetailsStatus !== QueryStatus.Success) {
    return null;
  }

  const isSignup = !transactionDetails.update_flow;
  const isFlywheel = transactionDetails.billing_system === 'flywheel';
  let page;

  // Load the correct theme and page depending on if it's a new Signup or an Update or Flywheel.
  if (isFlywheel) {
    defaultTheme = createTheme(flywheelTheme);
    page = <FlywheelAddPaymentMethodPage />;
  } else if (isSignup) {
    defaultTheme = createTheme(signupPaymentTheme, commonTheme);
    page = <SignupPaymentPage />;
  } else {
    defaultTheme = createTheme(updatePaymentInfoTheme, commonTheme);
    page = <UpdatePaymentInfoPage />;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <AppContainer>
        <Header />
        {page}
      </AppContainer>
    </ThemeProvider>
  );
}

export default App;
