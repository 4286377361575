import { styled, FormControl, FormControlProps } from '@material-ui/core';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: '1fr 2fr',
  gridTemplateRows: '1fr',
  marginBottom: '30px',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '25px',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '25px 1fr',
  },
}));

interface Props {
  children: React.ReactNode;
}

const FormFieldContainer = ({ children, ...otherProps }: Props & FormControlProps) => {
  return <StyledFormControl {...otherProps}>{children}</StyledFormControl>;
};

export default FormFieldContainer;
