import { createContext, ReactElement, useContext, useEffect, useState } from 'react';
import { useCybersource } from 'hooks/useCybersource';
import { createFlexMicroform } from './createFlexMicroform';
import { useURLParams } from 'hooks/useURLParams';

export const InitialContextValues = {
  microform: null,
  number: null,
  securityCode: null,
  numberLoaded: false,
  securityCodeLoaded: false,
};

interface microformField {
  on: (arg0: string, arg1: (e?: any) => void) => void;
  focus: () => void;
}

interface microform {
  createField: () => void;
  createToken: (
    options: { expirationMonth: string | undefined; expirationYear: string | undefined },
    arg1: (err: any, token: string) => void,
  ) => void;
}

export interface flexMicroform {
  microform: microform | null;
  number: microformField | null;
  securityCode: microformField | null;
}

export interface ICybersourceContext extends flexMicroform {
  numberLoaded: boolean;
  securityCodeLoaded: boolean;
}

export const CybersourceContext = createContext<ICybersourceContext>(InitialContextValues);

const CybersourceProvider = ({ children }: { children: ReactElement }) => {
  const { getCybersourceKey } = useCybersource();
  const transactionId = useURLParams('transaction_id');
  const { data } = getCybersourceKey;
  const [microform, setMicroform] = useState<flexMicroform>(InitialContextValues);
  const [numberLoaded, setNumberLoaded] = useState<boolean>(false);
  const [securityCodeLoaded, setSecurityCodeLoaded] = useState<boolean>(false);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        const flexMicroformData = await createFlexMicroform(data?.publicKey);
        setMicroform(flexMicroformData);
      } catch (err) {
        setMicroform(InitialContextValues);
      }
    })();
  }, [data?.publicKey]);

  useEffect(() => {
    if (data?.orgId && data?.merchantId) {
      //add fingerprinting iframe to the page
      const head = document.querySelector('head');
      const script = document.createElement('script');

      script.setAttribute('type', 'text/javascript');
      script.setAttribute(
        'src',
        `https://h.online-metrix.net/fp/tags.js?org_id=${data?.orgId}&session_id=${data?.merchantId}${transactionId}`,
      );
      head?.appendChild(script);
    }
  }, [data?.orgId, data?.merchantId, transactionId]);

  useEffect(() => {
    microform?.number?.on('load', () => {
      setNumberLoaded(true);
    });
    microform?.securityCode?.on('load', () => {
      setSecurityCodeLoaded(true);
    });
  }, [microform?.number, microform?.securityCode]);

  return (
    <CybersourceContext.Provider
      value={{
        numberLoaded,
        securityCodeLoaded,
        ...microform,
      }}
    >
      {children}
    </CybersourceContext.Provider>
  );
};

const useCybersourceContext = () => {
  const context = useContext(CybersourceContext);
  if (context === undefined) {
    throw new Error('useCybersourceContext must be used within a CybersourceProvider');
  }
  return context;
};

export { CybersourceProvider, useCybersourceContext };
