const errors = {
  default: 'Error processing your payment.',
  cardNumber: 'Error processing your card number. Please correct the information.',
  securityCode: 'Error processing card security code. Please correct the information.',
  cardNumberAndSecurityCode: 'Error processing card number and card security code. Please correct the information.',
  expiryDate: 'Error processing your payment. Enter an expiry date.',
  emptyCardNumber: 'Card number is required',
  emptyExpiryDate: 'Expiry date is required',
  emptySecurityCode: 'CVC is required',
};

export default errors;
