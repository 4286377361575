import { SvgIcon, SvgIconProps } from '@material-ui/core';
import { colors } from 'constants/colors';

const ErrorIcon = (props: SvgIconProps): JSX.Element => {
  const { height, width, fill } = props;
  return (
    <SvgIcon height={height || '24px'} width={width || '24px'} viewBox="0 0 24 24" data-testid="error-icon" {...props}>
      <path
        d="M11.249 0C9.0242 0 6.84931 0.659801 4.99941 1.89597C3.14952 3.13213 1.7077 4.88914 0.856287 6.94481C0.00487414 9.00048 -0.217895 11.2625 0.216152 13.4448C0.650199 15.6271 1.72157 17.6316 3.29477 19.205C4.86798 20.7783 6.87237 21.8498 9.05447 22.2838C11.2366 22.7179 13.4984 22.4951 15.5539 21.6436C17.6094 20.7922 19.3662 19.3502 20.6023 17.5002C21.8383 15.6501 22.4981 13.475 22.4981 11.25C22.4947 8.26735 21.3085 5.40783 19.1996 3.29878C17.0907 1.18972 14.2314 0.0033746 11.249 0ZM2.24981 11.25C2.24838 9.56582 2.71989 7.91509 3.61065 6.4858C4.50141 5.05651 5.77562 3.90612 7.28815 3.16564C8.80068 2.42515 10.4907 2.12435 12.1659 2.29747C13.841 2.4706 15.4338 3.1107 16.763 4.14488L4.14453 16.7644C2.91528 15.1892 2.24831 13.2481 2.24981 11.25ZM11.249 20.25C9.25177 20.2511 7.31151 19.5841 5.73702 18.3551L18.3536 5.7375C19.3871 7.06678 20.0267 8.65956 20.1995 10.3345C20.3723 12.0095 20.0714 13.6993 19.3311 15.2116C18.5907 16.724 17.4406 17.998 16.0118 18.8888C14.583 19.7795 12.9328 20.2512 11.249 20.25Z"
        fill={fill || colors.cherry}
      />
    </SvgIcon>
  );
};

export default ErrorIcon;
