import { SvgIcon, SvgIconProps } from '@material-ui/core';
import { flywheelColors } from 'constants/colors';

const InfoTipIcon = (props: SvgIconProps): JSX.Element => {
  const { height, width, fill } = props;
  return (
    <SvgIcon
      height={height || '18px'}
      width={width || '18px'}
      viewBox="0 0 18 18"
      data-testid="tooltip-icon"
      {...props}
    >
      <path
        aria-label="info"
        d="M9.95,5.75V6a.71.71,0,0,1-.75.74H8.8A.7.7,0,0,1,8.05,6V5.75A.71.71,0,0,1,8.8,5h.4A.71.71,0,0,1,9.95,5.75Zm-.75,2.1H8.8a.71.71,0,0,0-.74.75v3.65A.7.7,0,0,0,8.8,13h.4a.7.7,0,0,0,.74-.75V8.6A.71.71,0,0,0,9.19,7.85ZM18,9A9,9,0,1,1,9,0,9,9,0,0,1,18,9ZM16,9a7,7,0,1,0-7,7A7,7,0,0,0,16,9Z"
        fill={fill || flywheelColors.gray75}
      />
    </SvgIcon>
  );
};

export default InfoTipIcon;
