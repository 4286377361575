import { useState, useEffect } from 'react';
import ProgressStepper from 'components/ProgressStepper';
import { Formik, FormikHelpers } from 'formik';
import { merge } from 'lodash';
import TermsCopyContainer from 'components/TermsCopyContainer';
import { useTransaction } from 'hooks/useTransaction';
import { useTransactionDetails } from 'hooks/useTransactionDetails';
import { PageContainer } from './SignupPaymentPage';
import { FormStatus, StripeClientSecretRequest } from 'models/Billing';
import { Typography } from '@material-ui/core';
import { useStripe } from 'hooks/useStripe';

const StripeUBSPaymentPage = ({ portalConfirmationPageUrl }: { portalConfirmationPageUrl: string }) => {
  const { transactionDetails } = useTransactionDetails();
  const { transaction } = useTransaction();
  const { getClientSecret } = useStripe();
  const [clientSecretHook, setclientSecretHook] = useState();
  const isEsmSelfServe = transactionDetails?.signup_code?.includes('esm_self_serve');
  const accountDetailsAreNotPrepopulated = !(transactionDetails?.account_details_prepopulated === true);
  const isPendingSalesOrderWithoutCc = transactionDetails?.signup_code === 'pending_sales_order_without_cc';

  const stripePayload: StripeClientSecretRequest = {
    stripeId: '',
  };
  useEffect(() => {
    getClientSecret(stripePayload, {
      onSuccess: (response) => {
        //Set up Iframe
        setclientSecretHook(response);
      },
    });
  }, []);

  // Display terms & conditions if needed
  if (isPendingSalesOrderWithoutCc) {
    return (
      <PageContainer>
        <ProgressStepper />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <ProgressStepper />
      <Typography>THIS IS STRIPE UBS TESTING</Typography>
      <Typography>{transactionDetails.id.toString()}</Typography>
      <Typography>{JSON.stringify(clientSecretHook)}</Typography>
    </PageContainer>
  );
};

export default StripeUBSPaymentPage;
