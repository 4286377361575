import CreditCardIcon, { CreditCardBrand } from '../../assets/CreditCardIcon';
import { StyledHelperText, StyledLockIcon, useStyles } from '../CybersourceCardDetails/CybersourceCardDetailsStyles';
import { Fragment } from 'react';

interface props {
  paymentTypes?: CreditCardBrand[];
}
const defaultPaymentTypes: CreditCardBrand[] = ['american-express', 'discover', 'master-card', 'visa'];

const SecureConnectionText = ({ paymentTypes = defaultPaymentTypes }: props) => {
  const styles = useStyles();

  return (
    <div className="secure_connection-text">
      <StyledHelperText>
        <StyledLockIcon />
        {' Secure Connection '}
        <span className={styles.cardIconWrapper}>
          {paymentTypes.map((type, index) => {
            return (
              <Fragment key={type + index}>
                <CreditCardIcon className={styles.cardIcon} type={type} />
                {index !== paymentTypes?.length && ' '}
              </Fragment>
            );
          })}
        </span>
      </StyledHelperText>
    </div>
  );
};

export default SecureConnectionText;
