import { Button, Box, styled } from '@material-ui/core';
import { useURLParams } from 'hooks/useURLParams';
import { useState } from 'react';

const InvoiceBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const InvoiceButton = styled(Button)({
  fontSize: '14px',
  fontWeight: 'bold',
});

const Invoices = () => {
  const transactionId = useURLParams('transaction_id');
  const [linkText, setLinkText] = useState('Click here to download your invoice PDF');
  const [loading, setLoading] = useState(false);

  const downloadPDF = (transactionId: string | null): void => {
    const url = `/signup-api/v1/transaction/invoices/` + transactionId;
    fetch(url, { method: 'GET' })
      .then((response) => response.json())
      .then((invoices) => {
        console.log(invoices);
        if (invoices) {
          invoices.forEach((invoice: any) => {
            downloadEncodedPDF(invoice.invoice, invoice.invoice_number);
            setLinkText('Check your downloads folder to view invoice');
            setLoading(false);
          });
        } else {
          setLinkText('Download failed. Try again later');
          console.error('An error occured downloading the pdf.');
        }
      });
  };

  return (
    <InvoiceBox>
      <InvoiceButton
        disabled={loading}
        variant="text"
        onClick={() => {
          setLinkText('Loading please wait');
          setLoading(true);
          downloadPDF(transactionId);
        }}
      >
        {linkText}
      </InvoiceButton>
    </InvoiceBox>
  );
};

const downloadEncodedPDF = (encodedPDF: string, name: string): void => {
  const element = document.createElement('a');
  element.href = `data:application/pdf;base64,${encodedPDF}`;
  element.download = `${name}.pdf`;
  element.click();
};

export default Invoices;
