import { Region } from 'models/Region';

const allCountries = require('country-region-data/data.json');

interface CountryData {
  countryName: string;
  countryShortCode: string;
  regions: { name: string; shortCode: string }[];
}

// This hook is used retrieve regions/states of a particular country.
export const useRegions = (countryCode: string) => {
  const countries: CountryData[] = (allCountries as CountryData[])
    .filter(({ countryShortCode }) => countryShortCode === countryCode)
    .map(({ countryName, countryShortCode, regions }) => ({
      countryName: countryName,
      countryShortCode: countryShortCode,
      regions: regions,
    }));

  if (countries.length === 0) {
    const regions: Region[] = [];
    return { regions };
  }

  const regions: Region[] = countries[0].regions
    .map(({ name, shortCode }) => ({ name: name, code: shortCode }))
    .sort((regionA: Region, regionB: Region) => regionA.name.localeCompare(regionB.name));

  return { regions };
};
