import { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { FormStatus } from 'models/Billing';
import BillingContainer from 'components/BillingContainer';
import { useCybersourceContext } from 'contexts/CybersourceContext';

interface Props {
  errorMessage: string;
  portalInvoicePageUrl?: string;
  isUpdatePage?: boolean;
}

export const CybersourceBillingWrapper = ({ errorMessage, portalInvoicePageUrl, isUpdatePage }: Props) => {
  const [cyberSourceError, setCyberSourceError] = useState<string>('');
  const { numberLoaded, securityCodeLoaded } = useCybersourceContext();
  const { setStatus } = useFormikContext();
  const loaded = numberLoaded && securityCodeLoaded;

  useEffect(() => {
    let isMounted = true;
    if (!loaded) {
      setStatus(FormStatus.Loading);
      setTimeout(() => {
        if (isMounted && !loaded) {
          setCyberSourceError('Something went wrong, please contact support.');
        }
      }, 5000);
    }
    if (loaded) {
      setStatus(undefined);
    }

    return () => {
      isMounted = false;
    };
  }, [loaded, setStatus]);

  return (
    <BillingContainer
      errorMessage={cyberSourceError || errorMessage}
      portalInvoicePageUrl={portalInvoicePageUrl}
      cybersource
      isUpdatePage={isUpdatePage}
    />
  );
};

export default CybersourceBillingWrapper;
