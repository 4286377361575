import { styled } from '@material-ui/core';
import CartSummary from 'components/CartSummary/CartSummary';
import TermsCheckbox from 'components/TermsCheckbox';
import PayButton from 'components/PayButton';
import BadgeList from 'components/Badging/BadgeList';

const StyledBox = styled('aside')({
  display: 'flex',
  flex: '1',
  flexDirection: 'column',
  gridArea: 'sidebar',
  borderRadius: '5px',
  gap: '10px',
  textAlign: 'center',
});

const Sidebar = () => {
  return (
    <StyledBox>
      <CartSummary />
      <TermsCheckbox />
      <PayButton />
      <BadgeList />
    </StyledBox>
  );
};

export default Sidebar;
