import { useCountries } from 'hooks/useCountries';
import { useTransactionDetails } from 'hooks/useTransactionDetails';
import FormTextField from './FormTextField';

const australiaVATMessage =
  'The reason we have asked for your ABN number is to confirm that you are GST registered. If you provide your ABN number, we are assuming that you are GST registered.';

const singaporeVATMessage =
  'The reason we have asked for your GST number is to confirm that you are GST registered. If you provide your GST number, we are assuming that you are GST registered.';

const defaultVATMessage =
  'By providing your VAT ID, we are assuming that you are registered with the appropriate tax authorities.';

const getTooltip = (countryCode: string) => {
  switch (countryCode) {
    case 'AU':
      return australiaVATMessage;
    case 'SG':
      return singaporeVATMessage;
    default:
      return defaultVATMessage;
  }
};

interface Props {
  country: string;
}

const VatField = ({ country }: Props) => {
  const tooltip = getTooltip(country);
  const { transactionDetails } = useTransactionDetails();
  const { vatCountries } = useCountries();

  // Only show VAT ID for certain countries. Don't show for update or pending/sales assisted flow.
  if (
    !vatCountries.includes(country) ||
    transactionDetails.update_flow ||
    transactionDetails.signup_code === 'pending_invoice'
  ) {
    return null;
  }

  return <FormTextField label="VAT/GST ID (optional)" name="vatId" maxChars={25} tooltip={tooltip} />;
};

export default VatField;
