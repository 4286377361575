import { flexMicroform, InitialContextValues } from './CybersourceContext';

export const createFlexMicroform = (key: string): flexMicroform => {
  if (key) {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    let flex = new Flex(key);
    let microform = flex.microform();
    let number = microform.createField('number', { placeholder: 'Card number' });
    let securityCode = microform.createField('securityCode', { placeholder: 'CVC' });
    number.load('#credit-card_input');
    securityCode.load('#cvc_input');
    return { microform, number, securityCode };
  } else {
    return InitialContextValues;
  }
};
