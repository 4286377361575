import { styled, NativeSelect, SvgIcon } from '@material-ui/core';
import FormFieldLabel from 'components/BillingForm/FormFieldLabel';
import FormFieldContainer from 'components/BillingForm/FormFieldContainer';
import { Country } from 'models/Country';

interface Props {
  countries: Country[];
  defaultCountryCode: string;
  disabled: boolean;
  onCountryChanged: (countryCode: string) => void;
}

const StyledSvgIcon = styled(SvgIcon)(({ theme }) => ({
  fill: theme.palette.secondary.main,
  position: 'absolute',
  top: 'calc(50% - 7px)',
  right: '10px',
  pointerEvents: 'none',
}));

const FlywheelCountrySelector = ({ countries, defaultCountryCode, disabled, onCountryChanged }: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onCountryChanged(event.target.value);
  };

  return (
    <FormFieldContainer>
      <FormFieldLabel htmlFor="country">Country</FormFieldLabel>
      <NativeSelect
        id="country"
        onChange={handleChange}
        value={defaultCountryCode}
        disabled={disabled}
        IconComponent={() => (
          <StyledSvgIcon viewBox="0 0 14 6" width="14" height="6">
            <path d="M14 1.002a1 1 0 0 0-1.546-.836L6.97 3.742 1.55.167a1 1 0 0 0-1.1 1.67l5.967 3.936a1 1 0 0 0 1.097.002l6.032-3.935A.998.998 0 0 0 14 1.002z" />
          </StyledSvgIcon>
        )}
      >
        {countries?.map((country: Country) => (
          <option key={country.code} value={country.code}>
            {country.name}
          </option>
        ))}
      </NativeSelect>
    </FormFieldContainer>
  );
};

export default FlywheelCountrySelector;
