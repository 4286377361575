import { useEffect, useState } from 'react';
import {
  CybersourceBillingPayload,
  CybersourceFormikBillingValues,
  CybersourceFormikInitialBillingValues,
  CybersourcePayerAuthResponse,
  CybersourcePayerAuthSetup,
  FormStatus,
} from 'models/Billing';
import * as Yup from 'yup';
import { ZipRegex } from 'utils/validationUtils';
import { Container } from '@material-ui/core';
import { Formik, FormikHelpers } from 'formik';
import cybersourceErrors from 'pages/shared/CybersourcePaymentPageErrors';
import { useCybersource } from 'hooks/useCybersource';
import { useCybersourceContext } from 'contexts/CybersourceContext';
import CybersourceBillingWrapper from 'components/CybersourceBillingWrapper';
import PayerAuthIframe from 'components/PayerAuthIframe';
import parseJwt from 'utils/jwtUtils';
import { useTransactionDetails } from 'hooks/useTransactionDetails';

const validationSchema = Yup.object({
  firstName: Yup.string().required(`First name is required`),
  lastName: Yup.string().required(`Last name is required`),
  email: Yup.string().email('Please enter a valid email').required('Email is required'),
  billingAddress: Yup.string().required(`Billing address is required`),
  region: Yup.string().required(`State / Region is required`),
  city: Yup.string().required(`City is required`),
  zip: Yup.string().when('country', {
    is: 'US',
    then: Yup.string()
      .matches(ZipRegex, `Zip / Postal code must be 5 digits`)
      .required(`Zip / Postal code is required`),
    otherwise: Yup.string().required(`Zip / Postal code is required`),
  }),
});

const CybersourcePaymentPage = ({
  initialValues,
  portalInvoicePageUrl,
}: {
  initialValues: CybersourceFormikInitialBillingValues;
  portalInvoicePageUrl: string;
}) => {
  const { microform } = useCybersourceContext();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [billingPayload, setBillingPayload] = useState<CybersourceBillingPayload>();
  const [payerAuthSetupPayload, setPayerAuthSetupPayload] = useState<CybersourcePayerAuthSetup>();
  const [payerAuthSetupResponse, setPayerAuthSetupResponse] = useState<CybersourcePayerAuthResponse>();
  const [tokenHook, setToken] = useState<string>('');
  const { transactionDetails } = useTransactionDetails();
  const { setupPayerAuthStep1, submitPayerAuthIframe } = useCybersource();

  useEffect(() => {
    if (payerAuthSetupResponse) {
      submitPayerAuthIframe();
    }
  }, [payerAuthSetupResponse, submitPayerAuthIframe]);

  return (
    <Container maxWidth="md">
      <Formik
        initialValues={{ cardType: [{ name: '' }], ...initialValues }}
        validationSchema={validationSchema}
        onSubmit={async (
          values: CybersourceFormikBillingValues,
          helpers: FormikHelpers<CybersourceFormikBillingValues>,
        ) => {
          // Use formik state to set global form status
          helpers.setStatus(FormStatus.Submitting);
          setErrorMessage('');
          let currentYear = new Date().getFullYear();

          if (!values.creditCardExpiryDate) {
            helpers.setStatus(FormStatus.Error);
            helpers.setFieldError('creditCardExpiryDate', cybersourceErrors.expiryDate);
            return;
          }

          const options = {
            expirationMonth: values.creditCardExpiryDate?.split('/')[0].trim(),
            expirationYear: values.creditCardExpiryDate?.split('/')[1]?.trim()
              ? currentYear.toString().slice(0, -2) + values.creditCardExpiryDate?.split('/')[1]?.trim()
              : undefined,
          };

          await microform?.createToken(options, (err: any, token: string) => {
            if (err) {
              helpers.setStatus(FormStatus.Error);
              const hasCardNumberError = err?.details?.some((e: any) => e?.location === 'number');
              const hasCvcError = err?.details?.some((e: any) => e?.location === 'securityCode');
              if (hasCardNumberError) {
                helpers.setFieldError('creditCard', cybersourceErrors.cardNumber);
              } else if (hasCvcError) {
                helpers.setFieldError('creditCard', cybersourceErrors.securityCode);
              } else if (hasCardNumberError && hasCvcError) {
                helpers.setFieldError('creditCard', cybersourceErrors.cardNumberAndSecurityCode);
              } else {
                helpers.setFieldError('creditCard', cybersourceErrors.default);
              }
              return;
            }
            if (token) {
              const payload: CybersourceBillingPayload = {
                address1: values.billingAddress,
                address2: values.billingAddress2,
                city: values.city,
                state: values.region,
                country: values.country,
                zip: values.zip,
                transientTokenJwt: token,
                vatId: values.vatId,
                firstName: values.firstName,
                lastName: values.lastName,
                cardType: values.cardType[0]?.name,
                email: values.email,
                phoneNumber: values.phoneNumber,
                company: values.company,
                accountFirstName: values.accountFirstName,
                accountLastName: values.accountLastName,
                currency: 'USD',
                totalAmount: '1',
              };
              setToken(token);
              setBillingPayload(payload);
              const tokenData = parseJwt(token);
              const payerAuthSetupPayload: CybersourcePayerAuthSetup = {
                clientReferenceInformation: { code: transactionDetails.id },
                tokenInformation: { transientToken: tokenData?.jti },
                card: {
                  type: tokenData?.data?.type,
                  expirationMonth: tokenData?.data?.expirationMonth,
                  expirationYear: tokenData?.data?.expirationYear,
                  number: tokenData?.content?.paymentInformation?.card?.number?.bin,
                },
              };
              setPayerAuthSetupPayload(payerAuthSetupPayload);
              setupPayerAuthStep1(payerAuthSetupPayload, {
                onSuccess: (response) => {
                  //Set up Iframe
                  setPayerAuthSetupResponse(response);
                },
                onError: (error: any) => {
                  setErrorMessage('An error occurred while setting up Payer Authentication');
                },
              });
            }
          });
        }}
      >
        <>
          <PayerAuthIframe
            deviceDataCollectionURL={payerAuthSetupResponse?.consumerAuthenticationInformation.deviceDataCollectionUrl}
            accessToken={payerAuthSetupResponse?.consumerAuthenticationInformation.accessToken}
            payerAuthSetupPayload={payerAuthSetupPayload}
            payerAuthSetupResponse={payerAuthSetupResponse}
            billingPayload={billingPayload}
            tokenHook={tokenHook}
            portalConfirmationPageUrl={portalInvoicePageUrl}
            setErrorMessage={setErrorMessage}
            isUpdateFlow={true}
          />
          <CybersourceBillingWrapper
            errorMessage={errorMessage}
            portalInvoicePageUrl={portalInvoicePageUrl}
            isUpdatePage
          />
        </>
      </Formik>
    </Container>
  );
};

export default CybersourcePaymentPage;
