import { useFormikContext } from 'formik';
import { useCybersource } from 'hooks/useCybersource';
import { useTransaction } from 'hooks/useTransaction';
import { useTransactionDetails } from 'hooks/useTransactionDetails';
import {
  ChallengeWindowSize,
  CybersourceBillingPayload,
  CybersourcePayerAuthEnrollment,
  CybersourcePayerAuthEnrollmentResponse,
  CybersourcePayerAuthResponse,
  CybersourcePayerAuthSetup,
  CybersourcePareqResponse,
  FormStatus,
  CybersourcePayerAuthValidation,
  CybersourceLogging,
} from 'models/Billing';
import { useEffect, useCallback, useState, useMemo } from 'react';
import setSegmentCookie, { getCookie } from 'utils/CybersourceSetSegmentCookie';
import parseJwt from 'utils/jwtUtils';
import BaseModal from './Modal';
import buildDeviceInformation from 'utils/3dsUtils';

interface Props {
  deviceDataCollectionURL?: string;
  accessToken?: string;
  payerAuthSetupPayload: CybersourcePayerAuthSetup | undefined;
  payerAuthSetupResponse: CybersourcePayerAuthResponse | undefined;
  billingPayload: CybersourceBillingPayload | undefined;
  tokenHook: string;
  portalConfirmationPageUrl: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  isUpdateFlow: boolean;
}

const PayerAuthIframe = ({
  deviceDataCollectionURL,
  accessToken,
  payerAuthSetupPayload,
  payerAuthSetupResponse,
  billingPayload,
  tokenHook,
  portalConfirmationPageUrl,
  setErrorMessage,
  isUpdateFlow,
}: Props) => {
  const { sendBillingInfo, enrollmentCheckStep3, validationCheckStep5, logging } = useCybersource();
  const [payerAuthEnrollResponse, setPayerAuthEnrollResponse] = useState<CybersourcePayerAuthEnrollmentResponse>();
  const { transactionDetails } = useTransactionDetails();
  const { transaction } = useTransaction();
  const { setStatus } = useFormikContext();
  const [modalOpen, setModalOpen] = useState(false);
  const isEsmSelfServe = transactionDetails?.signup_code?.includes('esm_self_serve');
  const isPendingSalesOrder = transactionDetails?.signup_code?.includes('pending_sales_order');
  const [modalHeight, setModalHeight] = useState<number>(400);
  const [modalWidth, setModalWidth] = useState<number>(400);
  const nonPayerAuthCountryList = useMemo(() => {
    return ['US', 'CA', 'AU'];
  }, []);

  /**
   * Set the form status to error and display the error message.
   *
   * @param message - The error message to display.
   */
  const setFormError = useCallback(
    (message: string) => {
      setStatus(FormStatus.Error);
      setErrorMessage(message);
    },
    [setErrorMessage, setStatus],
  );

  const submitAuthorization = useCallback(
    (submitBillingPayload: CybersourceBillingPayload, payerAuthSetupResponse: CybersourcePayerAuthResponse): void => {
      submitBillingPayload.referenceId = payerAuthSetupResponse?.consumerAuthenticationInformation?.referenceId;
      sendBillingInfo(submitBillingPayload, {
        onSuccess: async () => {
          // Let marketing know we succeeded.
          if (!isUpdateFlow) {
            setSegmentCookie(transaction, transactionDetails);
            const WPESegmentBlobCookie = getCookie('WPESegmentBlob');
            //only log out in prod, this won't work in test
            if (!WPESegmentBlobCookie && window.location.host === 'payments.wpengine.com') {
              const loggingBlobPayload: CybersourceLogging = {
                transactionId: transactionDetails.id,
                logLevel: 'error',
                message: `SegmentBLob Missing`,
              };
              logging(loggingBlobPayload);
            }
          }

          let email = '';

          if (isEsmSelfServe) {
            email = billingPayload?.accountEmail ?? '';
          } else if (isPendingSalesOrder) {
            email = billingPayload?.email ?? '';
          }

          window.location.href = `${portalConfirmationPageUrl}?email=${encodeURIComponent(email)}&transaction_id=${
            transactionDetails.id
          }`;
        },
        onError: (error: any) => {
          const loggingErrorPayload: CybersourceLogging = {
            transactionId: transactionDetails.id,
            logLevel: 'info',
            message: `$1 Auth Failed, ${error.toString()}`,
          };
          logging(loggingErrorPayload);
          if (error?.user_actionable === true) {
            setFormError(error?.message);
          } else {
            setFormError('We could not complete your purchase. Please contact our sales team.');
          }
        },
      });
    },
    [
      billingPayload?.accountEmail,
      billingPayload?.email,
      isEsmSelfServe,
      isPendingSalesOrder,
      isUpdateFlow,
      logging,
      portalConfirmationPageUrl,
      sendBillingInfo,
      setFormError,
      transaction,
      transactionDetails,
    ],
  );

  useEffect(() => {
    if (payerAuthSetupResponse && billingPayload) {
      let handlerEnrollmentFunction = (event: any) => {
        if (
          event.origin ===
          new URL(payerAuthSetupResponse?.consumerAuthenticationInformation?.deviceDataCollectionUrl).origin
        ) {
          window.removeEventListener('message', handlerEnrollmentFunction);
          const payload: CybersourcePayerAuthEnrollment = {
            orderInformation: {
              amountDetails: {
                currency: 'USD',
                totalAmount: '1',
              },
              billTo: {
                address1: billingPayload?.address1,
                address2: billingPayload?.address2,
                administrativeArea: billingPayload?.state,
                country: billingPayload?.country,
                locality: billingPayload?.city,
                firstName: billingPayload?.firstName,
                lastName: billingPayload?.lastName,
                phoneNumber: billingPayload?.phoneNumber,
                email: billingPayload?.email,
                postalCode: billingPayload?.zip.toString(),
              },
            },
            consumerAuthenticationInformation: {
              referenceId: payerAuthSetupResponse?.consumerAuthenticationInformation?.referenceId,
              referenceUrl: window.location.href,
              returnUrl: `https://${window.location.host}/risk/v1/step-up-challenge-redirect`,
              challengeCode: '04',
              deviceChannel: 'browser',
            },
            tokenInformation: { transientToken: parseJwt(tokenHook)?.jti },
            clientReferenceInformation: { code: transactionDetails.id },
            paymentInformation: {
              card: payerAuthSetupPayload?.card,
            },
            deviceInformation: buildDeviceInformation(transactionDetails.id),
          };
          enrollmentCheckStep3(payload, {
            onSuccess: (response) => {
              //don't force step-up to all countries
              if (
                response.status !== 'AUTHENTICATION_SUCCESSFUL' &&
                !nonPayerAuthCountryList.includes(billingPayload?.country)
              ) {
                setPayerAuthEnrollResponse(response);
                return;
              }

              // 3DS step-up is not required. Skip to end and process auth
              submitAuthorization(billingPayload, payerAuthSetupResponse);
            },
            onError: (error: any) => {
              setFormError('We could not complete your purchase. Please contact our sales team.');
            },
          });
        }
      };
      window.addEventListener('message', handlerEnrollmentFunction);
    }
  }, [
    payerAuthSetupResponse,
    billingPayload,
    enrollmentCheckStep3,
    nonPayerAuthCountryList,
    payerAuthSetupPayload?.card,
    setFormError,
    submitAuthorization,
    tokenHook,
    transactionDetails?.id,
  ]);

  useEffect(() => {
    if (payerAuthEnrollResponse && billingPayload && payerAuthSetupResponse) {
      if (payerAuthEnrollResponse.status === 'AUTHENTICATION_SUCCESSFUL') {
        //if the auth enroll was successful we don't need to do anything
        return;
      }
      if (payerAuthEnrollResponse.status !== 'PENDING_AUTHENTICATION') {
        // should we run the $1 auth anyway?
        setFormError('We could not complete your purchase. Please try another card or contact our sales team.');
        return;
      }

      var stepUpForm: HTMLFormElement = document.getElementById('step-up-form') as HTMLFormElement;
      if (stepUpForm) {
        const windowSize: ChallengeWindowSize = getAcsWindowSize(
          payerAuthEnrollResponse.consumerAuthenticationInformation.pareq,
        );
        let handlerValidationFunction = (event: any) => {
          // Check if message came from the WPE Go backend.
          if (!event?.data?.TransactionId || event.origin !== window.location.origin) {
            return;
          }

          window.removeEventListener('message', handlerValidationFunction);
          setModalOpen(false);

          const payload: CybersourcePayerAuthValidation = {
            orderInformation: {
              amountDetails: {
                currency: '$',
                totalAmount: '1',
              },
            },
            clientReferenceInformation: {
              code: transactionDetails.id,
            },
            consumerAuthenticationInformation: {
              authenticationTransactionId: event.data.TransactionId,
            },
            tokenInformation: { transientToken: parseJwt(tokenHook)?.jti },
          };

          validationCheckStep5(payload, {
            onSuccess: (validationResponse) => {
              if (validationResponse.status === 'AUTHENTICATION_SUCCESSFUL') {
                submitAuthorization(billingPayload, payerAuthSetupResponse);
              } else {
                setFormError('We could not complete your purchase. Please try another card or contact our sales team.');
              }
            },
            onError: (error: any) => {
              setFormError('We could not complete your purchase. Please try another card or contact our sales team.');
            },
          });
        };

        window.addEventListener('message', handlerValidationFunction);

        setModalHeight(windowSize.height);
        setModalWidth(windowSize.width);
        setModalOpen(true);

        stepUpForm.submit();
      }
    }
  }, [
    payerAuthEnrollResponse,
    billingPayload,
    enrollmentCheckStep3,
    nonPayerAuthCountryList,
    payerAuthSetupPayload?.card,
    tokenHook,
    transactionDetails?.id,
    payerAuthSetupResponse,
    validationCheckStep5,
    setFormError,
    submitAuthorization,
  ]);

  const handleModalClose = (event: object, reason: string): void => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      setModalOpen(false);
    }
  };

  /**
   * Get the ACS window size based on the pareq response
   *
   * @param pareq - The pareq response.
   * @returns The challenge window size.
   */
  const getAcsWindowSize = (pareq: string): ChallengeWindowSize => {
    const pareqJson: string = atob(pareq);
    const pareqResponse: CybersourcePareqResponse = JSON.parse(pareqJson);
    let height: number = 400;
    let width: number = 400;

    switch (pareqResponse.challengeWindowSize) {
      case '01':
        height = 400;
        width = 250;
        break;
      case '02':
        height = 400;
        width = 390;
        break;
      case '03':
        height = 600;
        width = 500;
        break;
      case '04':
        height = 400;
        width = 600;
        break;
      case '05':
        height = window.screen.height;
        width = window.screen.width;
        break;
      default:
        break;
    }

    return {
      size: pareqResponse.challengeWindowSize,
      height,
      width,
    };
  };

  return (
    <div>
      <iframe
        id="cardinal_collection_iframe"
        name="collectionIframe"
        title="Cardinal Collection iFrame"
        height="1"
        width="1"
        style={{ display: 'none' }}
      ></iframe>
      <form id="cardinal_collection_form" method="POST" target="collectionIframe" action={deviceDataCollectionURL}>
        <input id="cardinal_collection_form_input" type="hidden" name="JWT" value={accessToken} />
      </form>

      <BaseModal open={modalOpen} handleClose={handleModalClose} height={modalHeight} width={modalWidth}>
        <iframe
          name="setpUpIframe"
          title="3DS Step Up"
          height={modalHeight}
          width={modalWidth}
          style={{ backgroundColor: '#ffffff' }}
        ></iframe>
        <form
          id="step-up-form"
          target="setpUpIframe"
          method="post"
          action={payerAuthEnrollResponse?.consumerAuthenticationInformation?.stepUpUrl}
        >
          <input
            type="hidden"
            name="JWT"
            value={payerAuthEnrollResponse?.consumerAuthenticationInformation?.accessToken}
          />
        </form>
      </BaseModal>
    </div>
  );
};

export default PayerAuthIframe;
