import { Typography, Box, styled } from '@material-ui/core';
import { ReactNode } from 'react';
import BadgeModal from 'components/Badging/BadgeModal';

const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '20px',
  textAlign: 'left',
});

const BadgeBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '90px',
});

const BadgeText = styled(Typography)({
  fontWeight: 'bold',
});

interface Props {
  badge: ReactNode;
  text?: string;
  modal?: boolean;
}

const Badge = ({ badge, text, modal }: Props) => {
  let badgeText;

  if (modal) {
    badgeText = <BadgeModal />;
  } else {
    badgeText = <BadgeText variant="subtitle2">{text}</BadgeText>;
  }

  return (
    <StyledBox role="listitem">
      <BadgeBox>{badge}</BadgeBox>
      {badgeText}
    </StyledBox>
  );
};

export default Badge;
