import { Box, styled, Step, Stepper, StepLabel, StepConnector, Typography } from '@material-ui/core';
import { colors } from 'constants/colors';

const StyledBox = styled(Box)({
  display: 'flex',
  marginTop: 0,
  justifyContent: 'center',
  alignItems: 'center',
  position: 'sticky',
  zIndex: 1,
  top: 0,
  gridArea: 'stepper',
  backgroundColor: colors.grey,
});

const StyledStepper = styled(Stepper)(({ theme }) => ({
  padding: 0,
  paddingTop: '35px',
  // Breakpoint which triggers on smaller screens
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: 0,
    paddingTop: '25px',
  },
}));

const ProgressStepper = () => {
  return (
    <StyledBox>
      <StyledStepper alternativeLabel activeStep={1} connector={<StepConnector />}>
        <Step>
          <StepLabel>
            <Typography variant="h4">Account</Typography>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            <Typography variant="h4">Payment</Typography>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            <Typography>Complete</Typography>
          </StepLabel>
        </Step>
      </StyledStepper>
    </StyledBox>
  );
};

export default ProgressStepper;
