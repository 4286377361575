import { ThemeOptions } from '@material-ui/core';
import { colors } from 'constants/colors';

const signupPaymentTheme: ThemeOptions = {
  palette: {
    background: {
      default: colors.grey,
    },
    primary: {
      main: colors.blue,
    },
    secondary: {
      main: colors.mirage,
    },
  },
  overrides: {
    // There is a confusing misalignment in the mapping of terminology between
    // Material UI buttons and Unicorn buttons.
    // Therefore, here's a table to make the mapping easier to understand:

    //  MUI         |   UNICORN
    //  -------------------------
    //  Default     |   Secondary
    //  Primary     |   Primary
    //  Secondary   |   Destruct
    //  Outlined    |   Tertiary
    //  Disabled    |   Disabled
    MuiStepper: {
      root: {
        backgroundColor: colors.grey,
        width: '672px',
      },
    },
    MuiStepLabel: {
      label: {
        color: colors.medGrey,
      },
    },
    MuiStepConnector: {
      alternativeLabel: {
        left: 'calc(-50% + 12px)',
        right: 'calc(50% + 12px)',
      },
      active: {
        '& $line': {
          borderColor: colors.mirage,
        },
      },
      line: {
        borderColor: colors.medGrey,
      },
    },
    MuiStepIcon: {
      root: {
        border: 'solid',
        borderWidth: 1,
        borderColor: colors.medGrey,
        borderRadius: '50%',
        width: '24px',
        '& $text': {
          fill: colors.medGrey,
        },
        '& circle': {
          display: 'none',
        },
        '&$active': {
          borderColor: colors.mirage,
          backgroundColor: colors.mirage,
          '& $text': {
            fill: 'white',
          },
        },
        '&$completed': {
          border: 'none',
          backgroundColor: 'white',
          color: colors.mirage,
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: '100px',
        fontSize: '1.125rem',
        fontWeight: 600,
        padding: '8px 24px',
        height: '38px',
        transition: 'none',
        '&:active': {
          boxShadow: 'inset 0px 4px 4px rgba(0, 40, 56, 0.4)',
        },
        '&$focusVisible': {
          outline: `2px solid ${colors.blue}`,
          outlineOffset: '2px',
        },
        '&$disabled': {
          backgroundColor: colors.polar10,
          color: colors.polar25,
        },
      },
      // MUI Default / UNICORN Secondary
      contained: {
        backgroundColor: colors.sunset,
        border: '1px solid transparent',
        boxShadow: 'none',
        color: colors.white,
        '&:active': {
          boxShadow: 'inset 0px 4px 4px rgba(0, 40, 56, 0.4)',
        },
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: colors.blueShade,
          color: colors.white,
        },
        '&$focusVisible': {
          boxShadow: 'none',
          backgroundColor: colors.mirageTint,
          color: colors.white,
          textDecoration: 'none',
        },
        '&$disabled': {
          backgroundColor: colors.polar10,
          color: colors.white,
        },
      },
      // MUI Primary / UNICORN Primary
      containedPrimary: {
        backgroundColor: colors.blue,
        borderRadius: '100px',
        '&:hover': {
          backgroundColor: colors.blueShade,
        },
        '&$focusVisible': {
          backgroundColor: colors.blueShade,
        },
      },
    },
    MuiToolbar: {
      root: {
        boxShadow: `inset -1px -1px 0px ${colors.polar10}`,
      },
    },
    MuiLink: {
      root: {
        color: colors.blue,
      },
    },
  },
  typography: {
    fontFamily: 'Inter',
    h1: {
      fontFamily: 'Lora',
      color: colors.navy,
    },
    h2: {
      fontFamily: 'Lora',
      color: colors.navy,
    },
    h3: {
      fontFamily: 'Lora',
      color: colors.navy,
      fontWeight: 500,
    },
    h4: {
      fontFamily: 'Inter',
      color: colors.navy,
    },
    h5: {
      fontFamily: 'Inter',
      color: colors.navy,
    },
    body1: {
      color: colors.navy,
    },
  },
};

export default signupPaymentTheme;
