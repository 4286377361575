import Button from 'components/Button';
import { useFormikContext } from 'formik';
import LockIcon from '@material-ui/icons/Lock';
import { CircularProgress } from '@material-ui/core';
import { FormStatus } from 'models/Billing';
import { useTransactionDetails } from 'hooks/useTransactionDetails';

const PayButton = () => {
  const { transactionDetails } = useTransactionDetails();
  const { status } = useFormikContext();
  const isSubmitting = status === FormStatus.Submitting;
  const isLoading = status === FormStatus.Loading;
  const icon = isSubmitting ? null : <LockIcon />;
  const loadingIcon = isSubmitting ? <CircularProgress size={18} color="inherit" /> : null;
  let buttonText = isSubmitting ? 'Processing' : 'Pay now';

  if (transactionDetails?.signup_code === 'pending_sales_order_without_cc') {
    buttonText = isSubmitting ? 'Processing' : 'I agree';
  }

  return (
    <Button
      form="billingForm"
      kind="primary"
      type="submit"
      disabled={isSubmitting || isLoading}
      startIcon={icon}
      endIcon={loadingIcon}
    >
      {buttonText}
    </Button>
  );
};

export default PayButton;
