import { styled, Grid } from '@material-ui/core';
import logoBlack from 'assets/wpe-full-logo-black-2024.svg';
import logoFW from 'assets/fw-full-logo-horizontal-blue.svg';
import iconPlus from 'assets/fw-icon-plus.svg';
import { useTransactionDetails } from 'hooks/useTransactionDetails';

const StyledGrid = styled(Grid)(() => ({
  paddingTop: '44px',
  paddingRight: '30px',
  paddingBottom: '20px',
  paddingLeft: '30px',
}));

const wpelogoHeight = 150.57;
const wpelogoWidth = 300;

const fwlogoHeight = 66.95;
const fwlogoWidth = 240;

const WPELink = styled('a')(({ theme }) => ({
  height: `${wpelogoHeight}px`,
  width: `${wpelogoWidth}px`,
  [theme.breakpoints.down('sm')]: {
    height: `calc(.75 * ${wpelogoHeight}px)`,
    width: `calc(.75 * ${wpelogoWidth}px)`,
  },
}));

const WPELogo = styled('img')(({ theme }) => ({
  height: `${wpelogoHeight}px`,
  width: `${wpelogoWidth}px`,
  [theme.breakpoints.down('sm')]: {
    height: `calc(.75 * ${wpelogoHeight}px)`,
    width: `calc(.75 * ${wpelogoWidth}px)`,
  },
}));

const FWLink = styled('a')(({ theme }) => ({
  height: `${fwlogoHeight}px`,
  width: `${fwlogoWidth}px`,
  [theme.breakpoints.down('sm')]: {
    height: `calc(.75 * ${fwlogoHeight}px)`,
    width: `calc(.75 * ${fwlogoWidth}px)`,
  },
}));

const FWLogo = styled('img')(({ theme }) => ({
  height: `${fwlogoHeight}px`,
  width: `${fwlogoWidth}px`,
  [theme.breakpoints.down('sm')]: {
    height: `calc(.75 * ${fwlogoHeight}px)`,
    width: `calc(.75 * ${fwlogoWidth}px)`,
  },
}));

const Icon = styled('img')(({ theme }) => ({
  height: '23px',
  width: '24px',
  margin: '0 20px',
}));

const DualLogoHeader = () => {
  const { transactionDetails } = useTransactionDetails();

  if (!transactionDetails) {
    return null;
  }

  return (
    <StyledGrid container direction="row" justifyContent="space-evenly" alignItems="center">
      <Grid container item xs={12} md={3} justifyContent="center">
        <WPELink href="https://wpengine.com" target="_blank">
          <WPELogo src={logoBlack} alt="WP Engine Logo" />
        </WPELink>
      </Grid>
      <Grid container item xs={12} md={1} justifyContent="center">
        <Icon src={iconPlus} alt="and" />
      </Grid>
      <Grid container item xs={12} md={3} justifyContent="center">
        <FWLink href="https://getflywheel.com" target="_blank">
          <FWLogo src={logoFW} alt="Flywheel Logo" />
        </FWLink>
      </Grid>
    </StyledGrid>
  );
};

export default DualLogoHeader;
