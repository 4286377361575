export const vatCountries: string[] = [
  'AT',
  'AU',
  'BE',
  'BG',
  'CA',
  'CH',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'EG',
  'ES',
  'FI',
  'FR',
  'GR',
  'GB',
  'HR',
  'HU',
  'ID',
  'IE',
  'IN',
  'IT',
  'KR',
  'LT',
  'LU',
  'LV',
  'MT',
  'MX',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'RU',
  'SE',
  'SG',
  'SI',
  'SK',
  'TR',
  'ZA',
];
