import { useQuery } from 'react-query';
import FetchAPI from 'services/FetchAPI';
import { CART_URL } from 'constants/urls';
import { CartSummary } from 'models/CartSummary';
import { useURLParams } from 'hooks/useURLParams';

// Fetches cart summary details based on transaction_id in URL
export const useCart = () => {
  const transactionId = useURLParams('transaction_id');
  const salesOrder = useURLParams('sales_order');

  const { status, data } = useQuery(
    'cartData',
    () => {
      return FetchAPI.get(`${CART_URL}/${transactionId}/`);
    },
    { enabled: !salesOrder },
  );

  const cart: CartSummary = data || null;

  return { status, cart };
};
